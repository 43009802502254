<template>
  <div class="genetics-form">
    <div>
      <BackButton />
    </div>
    <h3>Editing Genetics Details for Encounter:</h3>
    <h3>
      {{ this.$store.getters.getEncounterTitleByUUID(this.$route.params.uuid) }}
    </h3>
    <div class="inline-form">
      <div class="add-form">
        <h4 class="detail-title">Field Data</h4>
        <div class="detail-form row">
          <div class="col-md-3 col">
            <label>Material Sampled</label>

            <VueMultiselect
              id="materialSampled"
              v-model="form.materialSampled"
              placeholder="Select one or more option, or type material sampled"
              :options="this.materialSampledOptions"
              :taggable="true"
              :multiple="true"
              name="value"
              label="name"
              track-by="name"
              @tag="addMaterialsSampledTag"
              tag-placeholder="Add custom option"
              class="mb-0"
            />
          </div>
          <div class="col-md-3 col">
            <label>Other Sampled</label>
            <VueMultiselect
              id="otherSampling"
              v-model="form.otherSampling"
              placeholder="Select one or more option, or type other sampling"
              :options="this.otherSamplingOptions"
              :taggable="true"
              :multiple="true"
              name="value"
              label="name"
              track-by="name"
              @tag="addOtherSamplingTag"
              tag-placeholder="Add custom option"
              class="mb-0"
            />
          </div>
          <div class="col-md-3 col">
            <label>Location Taken</label>

            <VueMultiselect
              id="locationTaken"
              v-model="form.locationTaken"
              placeholder="Select one or more option, or type location taken"
              :options="this.locationTakenOptions"
              :taggable="true"
              :multiple="true"
              name="value"
              label="name"
              track-by="name"
              @tag="addLocationTakenTag"
              tag-placeholder="Add custom option"
              class="mb-0"
            />
          </div>
          <div class="col-md-3 col">
            <label>Preservation Medium</label>
            <VueMultiselect
              id="preservationMedium"
              v-model="form.preservationMedium"
              placeholder="Select one or more option, or type preservation medium"
              :options="this.preservationMediumOptions"
              :taggable="true"
              :multiple="true"
              name="value"
              label="name"
              track-by="name"
              @tag="addPreservationMediumTag"
              tag-placeholder="Add custom option"
              class="mb-0"
            />
          </div>
        </div>
      </div>
      <div class="inline-form">
        <div class="row mt-3 number-of-samples-vials">
          <label class="text-end col-sm-12 col-md-4 mt-2">
            Number of samples / vials
          </label>
          <input
            id="plastronWidth"
            type="number"
            class="type-input col-8 col-md-5 me-1"
            v-model="form.numberOfSamples"
          />
        </div>
        <div class="row mt-3 number-of-samples-vials">
          <label class="text-end col-sm-12 col-md-4 mt-2">
            Samples Collected For</label
          >
          <div class="col-10 col-md-5">
            <VueMultiselect
              id="samplesCollectedFor"
              v-model="form.samplesCollectedFor"
              placeholder="Select one or more option, or type samples collected for"
              :options="this.samplesCollectedForOptions"
              :taggable="true"
              :multiple="true"
              name="value"
              label="name"
              track-by="name"
              @tag="addSamplesCollectedForTag"
              tag-placeholder="Add custom option"
            />
          </div>
        </div>
        <div class="row mt-2 vial-label">
          <label class="text-end col-sm-12 col-md-4 mt-2">
            Vial label(s)
          </label>
          <input
            id="plastronWidth"
            class="type-input col-8 col-md-5 me-1"
            v-model="form.vialLabels"
          />
        </div>
        <div class="row mt-2 vial-label">
          <label class="text-end col-sm-12 col-md-4 mt-2">
            Storage Location
          </label>
          <input
            id="storageLocation"
            class="type-input col-8 col-md-5 me-1"
            v-model="form.storageLocation"
          />
        </div>
        <div class="row mt-2 where-sent">
          <label class="text-end col-sm-12 col-md-4 mt-2"> Where sent </label>
          <input
            id="plastronWidth"
            class="type-input col-8 col-md-5 me-1"
            v-model="form.whereSent"
          />
        </div>
      </div>
      <button
        href="#"
        class="save-button btn btn-primary mt-1 mb-3"
        @click.prevent="saveGenetics"
        type="submit"
      >
        Save Record
      </button>
    </div>
  </div>
</template>

<script>
import BackButton from "@/components/BackButton.vue";
import VueMultiselect from "vue-multiselect";

export default {
  name: "GeneticsForm",
  components: {
    BackButton,
    VueMultiselect,
  },
  mounted() {
    let encounterUUID = this.$route.params.uuid;
    if (this.$store.state.savedGenetics[encounterUUID]) {
      this.form = JSON.parse(
        JSON.stringify(this.$store.state.savedGenetics[encounterUUID])
      );
    } else {
      this.form.uuid = this.$route.params.uuid;
    }
  },
  data() {
    return {
      form: {
        uuid: null,
        materialSampled: [],
        otherSampling: [],
        locationTaken: [],
        preservationMedium: [],
        samplesCollectedFor: [],
        storageLocation: null,
        numberOfSamples: null,
        vialLabels: null,
        whereSent: null,
      },
      preservationMediumBaseOptions: [
        {
          name: "Dry",
          value: "field_preserv_med_dry",
        },
        {
          name: "Salt Solution",
          value: "field_preserv_med_salt",
        },
        {
          name: "Dry Salt",
          value: "field_preserv_med_drysalt",
        },
        {
          name: "DMSO Salt",
          value: "field_preserv_med_dmsosalt",
        },
        {
          name: "Blood lyses buffer",
          value: "field_preserv_med_blb",
        },
        {
          name: "Ethanol",
          value: "field_preserv_med_alc",
        },
      ],
      materialSampleCustomOptions: [],
      otherSamplingCustomOptions: [],
      locationTakenCustomOptions: [],
      preservationMediumCustomOptions: [],
      samplesCollectedForCustomOptions: [],
    };
  },
  computed: {
    materialSampledOptions() {
      let options = JSON.parse(
        JSON.stringify(
          this.$store.state.dropdownData.geneticsMaterialSampled.items
        )
      );
      JSON.parse(JSON.stringify(this.materialSampleCustomOptions)).forEach(
        (tag) => {
          options.push(tag);
        }
      );
      return options;
    },
    otherSamplingOptions() {
      let options = JSON.parse(
        JSON.stringify(this.$store.state.dropdownData.otherSampling.items)
      );
      JSON.parse(JSON.stringify(this.otherSamplingCustomOptions)).forEach(
        (tag) => {
          options.push(tag);
        }
      );
      return options;
    },
    locationTakenOptions() {
      let options = JSON.parse(
        JSON.stringify(
          this.$store.state.dropdownData.geneticsSampleLocation.items
        )
      );
      JSON.parse(JSON.stringify(this.locationTakenCustomOptions)).forEach(
        (tag) => {
          options.push(tag);
        }
      );
      return options;
    },
    preservationMediumOptions() {
      let options = JSON.parse(
        JSON.stringify(this.preservationMediumBaseOptions)
      );
      JSON.parse(JSON.stringify(this.preservationMediumCustomOptions)).forEach(
        (tag) => {
          options.push(tag);
        }
      );
      return options;
    },
    samplesCollectedForOptions() {
      let options = JSON.parse(
        JSON.stringify(this.$store.state.dropdownData.samplesCollectedFor.items)
      );
      JSON.parse(JSON.stringify(this.samplesCollectedForCustomOptions)).forEach(
        (tag) => {
          options.push(tag);
        }
      );
      return options;
    },
  },
  methods: {
    saveGenetics() {
      this.$store.commit("saveGenetics", this.form);
      this.$router.push({
        name: "EncounterMenu",
        params: { uuid: this.form.uuid },
      });
    },
    addMaterialsSampledTag(newTag) {
      const tag = {
        name: newTag,
        value: newTag,
      };
      this.materialSampleCustomOptions.push(tag);
      this.form.materialSampled.push(tag);
    },
    addOtherSamplingTag(newTag) {
      const tag = {
        name: newTag,
        value: newTag,
      };
      this.otherSamplingCustomOptions.push(tag);
      this.form.otherSampling.push(tag);
    },
    addLocationTakenTag(newTag) {
      const tag = {
        name: newTag,
        value: newTag,
      };
      this.locationTakenCustomOptions.push(tag);
      this.form.locationTaken.push(tag);
    },
    addPreservationMediumTag(newTag) {
      const tag = {
        name: newTag,
        value: newTag,
      };
      this.preservationMediumCustomOptions.push(tag);
      this.form.preservationMedium.push(tag);
    },
    addSamplesCollectedForTag(newTag) {
      const tag = {
        name: newTag,
        value: newTag,
      };
      this.samplesCollectedForCustomOptions.push(tag);
      this.form.samplesCollectedFor.push(tag);
    },
  },
};
</script>
