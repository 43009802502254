<template>
  <div>
    <div>
      <BackButton />
    </div>
    <div v-if="this.$route.params.uuid">
      <h3>Editing Core Details for Encounter:</h3>
      <h3>
        {{
          this.$store.getters.getEncounterTitleByUUID(this.$route.params.uuid)
        }}
      </h3>
    </div>

    <div v-else class="">
      <div  class="d-flex justify-content-center align-items-center my-3">
        <h3 class="mt-2 mb-0">New Encounter</h3>
      </div>
    </div>
    <div class="inline-form">
      <div class="add-form">
        <h4 class="detail-title">Basic Details</h4>
        <div class="detail-form row">
          <div class="col-md-4 col">
            <label class="required">Observation Type</label>
            <VueMultiselect
              id="observationType"
              v-model="this.form.observationType"
              :options="this.$store.state.dropdownData['observationType'].items"
              name="value"
              label="name"
              placeholder="Observation Type"
              required
            />
          </div>

          <div class="col-md-4 col">
            <label>Species</label>
            <VueMultiselect
              id="species"
              v-model="form.species"
              placeholder="Species"
              :options="this.$store.state.dropdownData['species'].items"
              name="value"
              label="name"
            />
          </div>

          <div class="col-md-4 col">
            <label>Sex</label>
            <VueMultiselect
              id="sex"
              v-model="form.sex"
              placeholder="sex"
              :options="this.$store.state.dropdownData['sex'].items"
              name="value"
              label="name"
            />
          </div>
        </div>
        <div
          v-if="this.form.observationType?.value === 'unique-encounter'"
          class="detail-form row"
        >
          <div class="col-md-4 col">
            <label class="required">Unique Encounter Details</label>

            <VueMultiselect
              id="uniqueEncounterDetails"
              v-model="form.uniqueEncounterDetails"
              placeholder="Details for unique encounter"
              :options="this.uniqueEncounterDetailsOptions"
              :taggable="true"
              name="value"
              label="name"
              @tag="addDetailsTag"
              tag-placeholder="Add custom option"
              class="mb-0"
            />
            <p class="col text-muted">
              For a custom unique detail option, enter text and select "Add
              option" button
            </p>
          </div>
        </div>
      </div>

      <div class="add-tag">
        <h4 class="tag-title">Tags</h4>
        <div>
          <span class="tag-help">Please enter all tags present or known to be removed. It is assumed that tags omitted but previously on the same turtle are no longer present.</span>
          <div
            v-for="(item, index) in this.form.tags"
            :key="index"
            class="extra-tags row pt-2 pb-2 ml-1 mr-1"
            style="background: rgba(255, 255, 255, 0.3)"
          >
            <div class="col-12 col-lg-4">
              <label :for="'tagNumber-' + index">Tag Number</label><br />
              <input
                :id="'tagNumber-' + index"
                v-model="item.tagNumber"
                placeholder="Tag Number"
                class="tag-input type-input"
              />
            </div>

            <div class="col-12 col-lg-4">
              <label :for="'tagPresence-' + index">Tag Presence</label><br />
              <VueMultiselect
                :id="'tagPresence-' + index"
                v-model="item.tagPresence"
                placeholder="Not Assessed"
                :options="this.$store.state.dropdownData['tagPresence'].items"
                name="value"
                label="name"
              />
            </div>

            <div class="col-12 col-lg-4">
              <label :for="'tagType-' + index">Tag Type</label><br />
              <VueMultiselect
                :id="'tagNumber-' + index"
                v-model="item.tagType"
                placeholder="None"
                :options="this.$store.state.dropdownData['tagType'].items"
                name="value"
                label="name"
              />
            </div>

            <div class="col-12 col-lg-4">
              <label :for="'tagPosition-' + index">Tag Position</label><br />
              <VueMultiselect
                :id="'tagPosition-' + index"
                v-model="item.tagPosition"
                placeholder="None"
                :options="this.$store.state.dropdownData['tagPosition'].items"
                name="value"
                label="name"
              />
            </div>

            <div class="col-12 col-lg-4">
              <label :for="'taggerName-' + index">Tagger Name (if known)</label><br />
              <input
                :id="'taggerName-' + index"
                v-model="item.taggerName"
                placeholder="Tagger Name"
                class="tag-input type-input"
              />
            </div>

            <div class="col-12 col-lg-4">
              <label :for="'appliedDate-' + index">Applied Date (if known)</label><br />
              <input
                :id="'appliedDate-' + index"
                type="date"
                v-model="item.appliedDate"
                placeholder="Applied Date"
                class="tag-input type-input"
              />
            </div>
          </div>
        </div>
        <p>
          <a href="#" @click.prevent="addTag" class="add-button">Add Tag</a>
        </p>
      </div>

      <div class="location-form add-form row">
        <div class="title">
          <h4 class="detail-title">Where the encounter occurred</h4>
        </div>

        <div class="location-from-wrapper row">
          <div class="row col-lg-6">
            <label class="required col-md-4 text-end">Locality</label>

            <VueMultiselect
              id="locality"
              v-model="form.locality"
              tag-placeholder="Add this as new locality"
              placeholder="Locality"
              :options="this.$store.state.dropdownData['locality'].items"
              :taggable="true"
              name="value"
              label="name"
              @tag="addLocalityTag"
            />
          </div>

          <div class="row col-lg-6">
            <label class="col-md-4 text-end">Site</label>
            <VueMultiselect
              id="site"
              v-model="form.site"
              tag-placeholder="Add this as new site"
              placeholder="Site"
              :options="this.$store.state.dropdownData['site'].items"
              :taggable="true"
              name="value"
              label="name"
              @tag="addSiteTag"
            />
          </div>

          <div class="row col-lg-6">
            <label class="col-md-4 text-end">Sector</label>
            <input
              id="sector"
              class="col-md-6 type-input"
              v-model="form.sector"
              placeholder="Sector"
            />
          </div>

          <div class="row col-lg-6">
            <label class="col-md-4 text-end">Habitat</label>
            <VueMultiselect
              id="habitat"
              v-model="form.habitat"
              placeholder="Habitat"
              :options="this.$store.state.dropdownData['habitat'].items"
              name="value"
              label="name"
            />
          </div>
        </div>

        <div class="geolocation-section">
          <h4 class="col-12 sub-title">Geolocation</h4>

          <div class="geolocation-wrapper row">
            <div class="row col-lg-6">
              <label class="col-md-4 text-end">Latitude</label>
              <input
                id="latitude"
                class="col-md-6 type-input"
                type="number"
                v-model="form.latitude"
                placeholder="Latitude"
              />
            </div>

            <div class="row col-lg-6">
              <label class="col-md-4 text-end">Longitude</label>
              <input
                id="longitude"
                class="col-md-6 type-input"
                type="number"
                v-model="form.longitude"
                placeholder="Longitude"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="date-form add-form row">
        <div class="title">
          <h4 class="detail-title">When it happened</h4>
        </div>

        <div class="date-form-wrapper row">
          <div class="row col-lg-6">
            <label class="required col-md-4 text-end">Date of encounter</label>
            <input
              id="encounterDate"
              class="col-md-6 type-input"
              type="date"
              v-model="form.encounterDate"
            />
          </div>

          <div class="row col-lg-6">
            <label class="col-md-4 text-end">Time (Optional)</label>
            <input
              id="encounterTime"
              class="type-input col-md-6"
              type="time"
              v-model="form.encounterTime"
            />
          </div>
        </div>
      </div>

      <div class="personel-form add-form row">
        <div class="title">
          <h4 class="detail-title">Who was there</h4>
        </div>

        <div class="personel-form-wrapper row">
          <div class="row col-lg-6">
            <label class="required col-md-4 text-end">Recorded By</label>
            <input
              id="recorder"
              class="col-md-6 type-input helptext-input"
              placeholder="Recorder"
              v-model="form.recorder"
            />
            <span class="col-md-4"></span>
            <p class="helptext col-md-6">
              Enter the name of the recorder. Please enter 'NA' if the name of
              the recorder is not known.
            </p>
          </div>

          <div class="row col-lg-6">
            <label class="col-md-4 text-end">Measured By</label>
            <input
              id="measuredBy"
              class="col-md-6 type-input"
              placeholder="Measured By"
              v-model="form.measuredBy"
            />
          </div>
        </div>
      </div>

      <div class="encounter-form add-form">
        <div class="title">
          <h4 class="detail-title">Encounter details</h4>
        </div>

        <div class="wrapper row-one">
          <div class="row col-sm-4">
            <label class="col-md-4 text-end">Encounter Type</label>
            <VueMultiselect
              id="encounterType"
              v-model="form.encounterType"
              placeholder="Encounter Type"
              :options="this.$store.state.dropdownData['encounterType'].items"
              name="value"
              label="name"
              :group-select="false"
              group-values="options"
              group-label="category"
            />
          </div>
          <div class="row col-sm-4">
            <label class="col-md-4 text-end">Tagging Status</label>
            <VueMultiselect
              id="taggingStatus"
              v-model="form.taggingStatus"
              placeholder="Tagging Status"
              :options="this.$store.state.dropdownData['taggingStatus'].items"
              name="value"
              label="name"
              :group-select="false"
              group-values="options"
              group-label="category"
            />
          </div>
          <div class="row col-sm-4">
            <label class="col-md-4 text-end">Encounter Activity</label>
            <VueMultiselect
              id="encounterActivity"
              v-model="this.form.encounterActivity"
              :options="
                this.$store.state.dropdownData['encounterActivity'].items
              "
              name="value"
              label="name"
              placeholder="Encounter Activity"
            />
          </div>
        </div>

        <div class="wrapper row-two">
          <div class="row col-sm-4">
            <label class="col-md-4 text-end">Encounter Condition</label>
            <VueMultiselect
              id="encounterCondition"
              v-model="form.encounterCondition"
              placeholder="Encounter Condition"
              :options="
                this.$store.state.dropdownData['encounterCondition'].items
              "
              name="value"
              label="name"
              :group-select="false"
              group-values="options"
              group-label="category"
            />
          </div>
          <div class="row col-sm-4">
            <label class="col-md-4 text-end">Age Class</label>
            <VueMultiselect
              v-model="form.ageClass"
              placeholder="Age Class"
              :options="this.$store.state.dropdownData['ageClass'].items"
              name="value"
              label="name"
            />
          </div>
        </div>

        <div class="wrapper row-three">
          <div class="row col-sm-4">
            <label class="text-end col-sm-12 col-md-4">CCL</label>
            <input
              id="ccl"
              class="type-input col-8 col-md-5"
              v-model="form.ccl"
              type="number"
              placeholder="CCL (cm)"
            />
            <span class="cm col-2">cm</span>
          </div>

          <div class="row col-sm-4">
            <label class="text-end col-sm-12 col-md-4">CCW</label>
            <input
              id="ccw"
              class="type-input col-8 col-md-5"
              v-model="form.ccw"
              type="number"
              placeholder="CCW (cm)"
            />
            <span class="cm col-2">cm</span>
          </div>

          <div class="row col-sm-4">
            <label class="text-end col-sm-12 col-md-4">Weight</label>
            <input
              id="weight"
              class="type-input col-8 col-md-5"
              v-model="form.weight"
              type="number"
              placeholder="Weight (kg)"
            />
            <span class="kg col-2">kg</span>
          </div>
        </div>

        <div class="row">
          <label class="col-md-4 text-end">Notes</label>
          <textarea
            id="notes"
            v-model="form.notes"
            placeholder="Notes"
            class="col-md-6 type-input"
            style="margin-left: 3px"
          />
        </div>
      </div>

      <div class="env-conditions-form add-form">
        <div class="title">
          <h4 class="detail-title">Environmental conditions</h4>
        </div>

        <div class="form-wrapper row">
          <div class="row col-lg-6">
            <label class="col-md-4 text-end">Wind Direction</label>
            <VueMultiselect
              v-model="form.windDirection"
              placeholder="Wind Direction"
              :options="this.$store.state.dropdownData['windDirection'].items"
              name="value"
              label="name"
            />
          </div>

          <div class="row col-lg-6">
            <label class="col-md-4 text-end">Wind Strength</label>
            <VueMultiselect
              v-model="form.windStrength"
              placeholder="Wind Strength"
              :options="this.$store.state.dropdownData['windStrength'].items"
              name="value"
              label="name"
            />
          </div>

          <div class="row col-lg-6">
            <label class="col-md-4 text-end">Cloud Cover</label>
            <VueMultiselect
              v-model="form.cloudCover"
              placeholder="Cloud Cover"
              :options="this.$store.state.dropdownData['cloudCover'].items"
              name="value"
              label="name"
            />
          </div>

          <div class="row col-lg-6">
            <label class="col-md-4 text-end">Sea Conditions</label>
            <VueMultiselect
              v-model="form.seaConditions"
              placeholder="Sea Conditions"
              :options="this.$store.state.dropdownData['seaConditions'].items"
              name="value"
              label="name"
            />
          </div>

          <div class="row col-lg-6">
            <label class="col-md-4 text-end">Rain</label>
            <VueMultiselect
              v-model="form.rain"
              placeholder="Rain"
              :options="this.$store.state.dropdownData['rain'].items"
              name="value"
              label="name"
            />
          </div>

          <div class="row col-lg-6">
            <label class="col-sm-12 col-md-4 text-end">Ambient Temp</label>
            <input
              id="ambientTemp"
              class="col-8 col-md-5 type-input"
              type="number"
              v-model="form.ambientTemp"
            />
            <span class="c col-1 text-left">&#176;C</span>
          </div>

          <div class="row col-lg-6">
            <label class="col-md-4 text-end">Water Depth</label>
            <VueMultiselect
              v-model="form.waterDepth"
              placeholder="Water Depth"
              :options="this.$store.state.dropdownData['waterDepth'].items"
              name="value"
              label="name"
            />
          </div>

          <div class="row col-lg-6">
            <label class="col-md-4 text-end">Bottom Composition</label>
            <VueMultiselect
              v-model="form.bottomComposition"
              placeholder="Bottom Composition"
              :options="
                this.$store.state.dropdownData['bottomComposition'].items
              "
              name="value"
              label="name"
            />
          </div>

          <div class="row col-lg-6">
            <label class="col-md-4 text-end">Shoreline Type</label>
            <VueMultiselect
              v-model="form.shorelineType"
              placeholder="Shoreline Type"
              :options="this.$store.state.dropdownData['shorelineType'].items"
              name="value"
              label="name"
            />
          </div>
        </div>
      </div>
      <div>
        <button
          href="#"
          class="save-button btn btn-primary mt-3 mb-1"
          :disabled="submitDisabled"
          @click.prevent="saveEncounter"
          type="submit"
        >
          Save Record
        </button>
        <br />
        <p class="form-error" v-if="submitDisabled">
          Please ensure all elements marked with a
          <strong class="required"> </strong> are filled
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import VueMultiselect from "vue-multiselect";
import { v1 as uuid } from "uuid";
import BackButton from "@/components/BackButton";

export default {
  name: "EncouterCoreForm",
  components: {
    VueMultiselect,
    BackButton,
  },
  data() {
    return {
      form: {
        uuid: null,
        locality: null,
        site: null,
        observationType: null,
        species: null,
        sex: null,
        sector: "",
        habitat: null,
        longitude: "",
        latitude: "",
        encounterDate: "",
        encounterTime: "",
        recorder: "",
        measuredBy: "",
        encounterType: null,
        taggingStatus: null,
        encounterActivity: null,
        encounterCondition: null,
        ageClass: null,
        ccl: "",
        ccw: "",
        weight: "",
        notes: "",
        windDirection: null,
        windStrength: null,
        cloudCover: null,
        seaConditions: null,
        rain: null,
        ambientTemp: "",
        waterDepth: null,
        bottomComposition: null,
        shorelineType: null,
        uniqueEncounterDetails: null,
        tags: [
          {
            tagNumber: "",
            tagPresence: null,
            tagType: null,
            tagPosition: null,
            taggerName: "",
            appliedDate: "",
          },
        ],
        uploaded: false,
      },
      uniqueEncounterDetailCustomOption: null,
    };
  },
  mounted() {
    let encounterUUID = this.$route.params.uuid;
    if (encounterUUID) {
      this.form = JSON.parse(
        JSON.stringify(this.$store.state.savedEncounters[encounterUUID])
      );
    }
  },
  computed: {
    submitDisabled() {
      let uniqueEncounterDetailsValid =
        this.form.observationType &&
        this.form.observationType.value === "unique-encounter"
          ? this.form.uniqueEncounterDetails
          : true;
      return !(
        this.form.observationType &&
        this.form.locality &&
        this.form.encounterDate &&
        this.form.recorder &&
        uniqueEncounterDetailsValid
      );
    },
    uniqueEncounterDetailsOptions() {
      let options = JSON.parse(
        JSON.stringify(
          this.$store.state.dropdownData.uniqueEncounterDetails.items
        )
      );
      if (this.uniqueEncounterDetailCustomOption) {
        options.push(
          JSON.parse(JSON.stringify(this.uniqueEncounterDetailCustomOption))
        );
      }
      return options;
    },
  },
  methods: {
    addTag() {
      // Then black out form fields for new item.
      this.form.tags.push({
        tagNumber: "",
        tagPresence: {
          value: "",
        },
        tagType: {
          value: "",
        },
        tagPosition: {
          value: "",
        },
        taggerName: "",
        appliedDate: "",
      });
    },
    saveEncounter() {
      this.form.uuid = this.form.uuid ? this.form.uuid : uuid();
      this.$store.commit("saveEncounter", this.form);
      this.$router.push({
        name: "EncounterMenu",
        params: { uuid: this.form.uuid },
      });
    },
    addLocalityTag(newTag) {
      const tag = {
        name: newTag,
        value: newTag,
      };
      this.$store.commit("addCustomDropdownOption", {
        listId: "locality",
        name: tag.name,
        value: tag.value,
      });
      this.form.locality = tag;
    },
    addSiteTag(newTag) {
      const tag = {
        name: newTag,
        value: newTag,
      };
      this.$store.commit("addCustomDropdownOption", {
        listId: "site",
        name: tag.name,
        value: tag.value,
      });
      this.form.site = tag;
    },
    addDetailsTag(newTag) {
      const tag = {
        name: newTag,
        value: newTag,
      };
      this.uniqueEncounterDetailCustomOption = tag;
      this.form.uniqueEncounterDetails = tag;
    },
  },
};
</script>
