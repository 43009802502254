<template>
  <div v-if="!this.error && this.$store.state.networkAccess" class="col p-3">
    <div class="mb-4">
      <h3>Data Push</h3>
    </div>
    <br />
    <div class="d-flex flex-column align-items-center">
      <RadialProgress
        :diameter="200"
        :completed-steps="doneCount"
        :total-steps="totalCount"
      >
        <div class="d-flex flex-column align-items-center">
          <h6 class="fs-5">Push progress:</h6>
          <span class="fs-3">{{ doneCount }} / {{ totalCount }} </span>
        </div>
      </RadialProgress>
    </div>

    <div v-if="doneCount === totalCount" class="m-3 alert alert-warning">
      <span>All Encounters Pushed Successfully</span>
    </div>
    <router-link  v-if="doneCount === totalCount" to="/">
      <button class="btn btn-primary mt-3">Back to Home</button>
    </router-link>
  </div>
</template>

<script>
import tredsApi from "../api/tredsApi";
import RadialProgress from "vue3-radial-progress";

export default {
  name: "UploadData",
  components: {
    RadialProgress,
  },
  data() {
    return {
      loading: true,
      error: false,
      totalCount: 0,
      doneCount: 0,
    };
  },
  mounted() {
    let uuids = this.$store.state.uploadArray;
    this.totalCount = uuids.length;

    const asyncLimit = (fn, n) => {
      const pendingPromises = new Set();
      return async function (...args) {
        while (pendingPromises.size >= n) {
          await Promise.race(pendingPromises);
        }

        const p = fn.apply(this, args);
        const r = p.catch(() => {});
        pendingPromises.add(r);
        await r;
        pendingPromises.delete(r);
        return p;
      };
    };

    let limitedUpload = asyncLimit(this.uploadUUID, 10);

    uuids.forEach((element) => {
      limitedUpload(element);
    });
  },
  methods: {
    uploadUUID(uuid) {
      let url = this.$store.state.baseUrl + "/data-entry-upload";

      let data = {};
      data.encounter = this.$store.state.savedEncounters[uuid] ?? {};
      data.satellite = this.$store.state.savedSatelliteDetails[uuid]  ?? {};
      data.measurement = this.$store.state.savedMeasurements[uuid] ?? {};
      data.genetics = this.$store.state.savedGenetics[uuid] ?? {};
      data.laparoscopy = this.$store.state.savedLaparoscopy[uuid] ?? {};
      data.tumor = this.$store.state.savedTumor[uuid] ?? {};

      return tredsApi
        .pushDataToTreds(
          this.$store.state.accessToken,
          url,
          this.$route.params.fieldwork,
          JSON.stringify(data),
          "import"
        )
        .then((result) => {
          this.$store.commit("markEncounterUploaded", uuid);
          this.doneCount++;
        })
        .catch((error) => {
          console.log(error);
          this.error = true;
        })
        .then(() => {
          this.loading = false;
        });
    },
  },

};
</script>