<template>
    <div>
      <BackButton />
    </div>
    <h3>Editing Tumor Details for Encounter:</h3>
    <h3>
      {{ this.$store.getters.getEncounterTitleByUUID(this.$route.params.uuid) }}
    </h3>
  <div class="inline-form tumor-form">
    <div class="encounter-form add-form my-4">
      <h4 class="detail-title p-2 col-12">Details</h4>
      <div class="row">
        <label class="col-2 mt-2">Right Eye</label>
        <div class="col-1 d-flex">
          <label for="right-eye-1" class="mt-2">1</label>
          <input
            class="type-input col-8"
            type="number"
            v-model="form.rightEye.one"
            id="right-eye-1"
          />
        </div>
        <div class="col-1 d-flex">
          <label for="right-eye-2" class="mt-2">2</label>
          <input
            class="type-input col-8"
            type="number"
            v-model="form.rightEye.two"
            id="right-eye-2"
          />
        </div>
        <div class="col-1 d-flex">
          <label for="right-eye-3" class="mt-2">3</label>
          <input
            class="type-input col-8"
            type="number"
            v-model="form.rightEye.three"
            id="right-eye-3"
          />
        </div>
        <div class="col-1 d-flex">
          <label for="right-eye-4" class="mt-2">4</label>
          <input
            class="type-input col-8"
            type="number"
            v-model="form.rightEye.four"
            id="right-eye-4"
          />
        </div>
        <div class="col-6 d-flex">
          <label for="right-eye-remarks" class="mt-2">Remarks</label>
          <input
            class="type-input col-8"
            v-model="form.rightEye.remarks"
            id="right-eye-remarks"
          />
        </div>
      </div>
      <div class="row">
        <label class="col-2 mt-2">Left Eye</label>
        <div class="col-1 d-flex">
          <label for="left-eye-1" class="mt-2">1</label>
          <input
            class="type-input col-8"
            type="number"
            v-model="form.leftEye.one"
            id="left-eye-1"
          />
        </div>
        <div class="col-1 d-flex">
          <label for="left-eye-2" class="mt-2">2</label>
          <input
            class="type-input col-8"
            type="number"
            v-model="form.leftEye.two"
            id="left-eye-2"
          />
        </div>
        <div class="col-1 d-flex">
          <label for="left-eye-3" class="mt-2">3</label>
          <input
            class="type-input col-8"
            type="number"
            v-model="form.leftEye.three"
            id="left-eye-3"
          />
        </div>
        <div class="col-1 d-flex">
          <label for="left-eye-4" class="mt-2">4</label>
          <input
            class="type-input col-8"
            type="number"
            v-model="form.leftEye.four"
            id="left-eye-4"
          />
        </div>
        <div class="col-6 d-flex">
          <label for="left-eye-remarks" class="mt-2">Remarks</label>
          <input
            class="type-input col-8"
            v-model="form.leftEye.remarks"
            id="left-eye-remarks"
          />
        </div>
      </div>
      <div class="row">
        <label class="col-2 mt-2">Mouth</label>
        <div class="col-1 d-flex">
          <label for="mouth-1" class="mt-2">1</label>
          <input
            class="type-input col-8"
            type="number"
            v-model="form.mouth.one"
            id="mouth-1"
          />
        </div>
        <div class="col-1 d-flex">
          <label for="mouth-2" class="mt-2">2</label>
          <input
            class="type-input col-8"
            type="number"
            v-model="form.mouth.two"
            id="mouth-2"
          />
        </div>
        <div class="col-1 d-flex">
          <label for="mouth-3" class="mt-2">3</label>
          <input
            class="type-input col-8"
            type="number"
            v-model="form.mouth.three"
            id="mouth-3"
          />
        </div>
        <div class="col-1 d-flex">
          <label for="mouth-4" class="mt-2">4</label>
          <input
            class="type-input col-8"
            type="number"
            v-model="form.mouth.four"
            id="mouth-4"
          />
        </div>
        <div class="col-6 d-flex">
          <label for="mouth-remarks" class="mt-2">Remarks</label>
          <input
            class="type-input col-8"
            v-model="form.mouth.remarks"
            id="mouth-remarks"
          />
        </div>
      </div>

      <div class="row">
        <label class="col-2 mt-2">Neck</label>
        <div class="col-1 d-flex">
          <label for="neck-1" class="mt-2">1</label>
          <input
            class="type-input col-8"
            type="number"
            v-model="form.neck.one"
            id="neck-1"
          />
        </div>
        <div class="col-1 d-flex">
          <label for="neck-2" class="mt-2">2</label>
          <input
            class="type-input col-8"
            type="number"
            v-model="form.neck.two"
            id="neck-2"
          />
        </div>
        <div class="col-1 d-flex">
          <label for="neck-3" class="mt-2">3</label>
          <input
            class="type-input col-8"
            type="number"
            v-model="form.neck.three"
            id="neck-3"
          />
        </div>
        <div class="col-1 d-flex">
          <label for="neck-4" class="mt-2">4</label>
          <input
            class="type-input col-8"
            type="number"
            v-model="form.neck.four"
            id="neck-4"
          />
        </div>
        <div class="col-6 d-flex">
          <label for="neck-remarks" class="mt-2">Remarks</label>
          <input
            class="type-input col-8"
            v-model="form.neck.remarks"
            id="neck-remarks"
          />
        </div>
      </div>

      <div class="row">
        <label class="col-2 mt-2">Right front flipper (RFF)</label>
        <div class="col-1 d-flex">
          <label for="rff-1" class="mt-2">1</label>
          <input
            class="type-input col-8"
            type="number"
            v-model="form.rff.one"
            id="rff-1"
          />
        </div>
        <div class="col-1 d-flex">
          <label for="rff-2" class="mt-2">2</label>
          <input
            class="type-input col-8"
            type="number"
            v-model="form.rff.two"
            id="rff-2"
          />
        </div>
        <div class="col-1 d-flex">
          <label for="rff-3" class="mt-2">3</label>
          <input
            class="type-input col-8"
            type="number"
            v-model="form.rff.three"
            id="rff-3"
          />
        </div>
        <div class="col-1 d-flex">
          <label for="rff-4" class="mt-2">4</label>
          <input
            class="type-input col-8"
            type="number"
            v-model="form.rff.four"
            id="rff-4"
          />
        </div>
        <div class="col-6 d-flex">
          <label for="rff-remarks" class="mt-2">Remarks</label>
          <input
            class="type-input col-8"
            v-model="form.rff.remarks"
            id="rff-remarks"
          />
        </div>
      </div>

      <div class="row">
        <label class="col-2 mt-2">Left front flipper (LFF)</label>
        <div class="col-1 d-flex">
          <label for="lff-1" class="mt-2">1</label>
          <input
            class="type-input col-8"
            type="number"
            v-model="form.lff.one"
            id="lff-1"
          />
        </div>
        <div class="col-1 d-flex">
          <label for="lff-2" class="mt-2">2</label>
          <input
            class="type-input col-8"
            type="number"
            v-model="form.lff.two"
            id="lff-2"
          />
        </div>
        <div class="col-1 d-flex">
          <label for="lff-3" class="mt-2">3</label>
          <input
            class="type-input col-8"
            type="number"
            v-model="form.lff.three"
            id="lff-3"
          />
        </div>
        <div class="col-1 d-flex">
          <label for="lff-4" class="mt-2">4</label>
          <input
            class="type-input col-8"
            type="number"
            v-model="form.lff.four"
            id="lff-4"
          />
        </div>
        <div class="col-6 d-flex">
          <label for="lff-remarks" class="mt-2">Remarks</label>
          <input
            class="type-input col-8"
            v-model="form.lff.remarks"
            id="lff-remarks"
          />
        </div>
      </div>
      <div class="row">
        <label class="col-2 mt-2">Right rear flipper (RHF)</label>
        <div class="col-1 d-flex">
          <label for="rrf-1" class="mt-2">1</label>
          <input
            class="type-input col-8"
            type="number"
            v-model="form.rrf.one"
            id="rrf-1"
          />
        </div>
        <div class="col-1 d-flex">
          <label for="rrf-2" class="mt-2">2</label>
          <input
            class="type-input col-8"
            type="number"
            v-model="form.rrf.two"
            id="rrf-2"
          />
        </div>
        <div class="col-1 d-flex">
          <label for="rrf-3" class="mt-2">3</label>
          <input
            class="type-input col-8"
            type="number"
            v-model="form.rrf.three"
            id="rrf-3"
          />
        </div>
        <div class="col-1 d-flex">
          <label for="rrf-4" class="mt-2">4</label>
          <input
            class="type-input col-8"
            type="number"
            v-model="form.rrf.four"
            id="rrf-4"
          />
        </div>
        <div class="col-6 d-flex">
          <label for="rrf-remarks" class="mt-2">Remarks</label>
          <input
            class="type-input col-8"
            v-model="form.rrf.remarks"
            id="rrf-remarks"
          />
        </div>
      </div>

      <div class="row">
        <label class="col-2 mt-2">Left rear flipper (LHF)</label>
        <div class="col-1 d-flex">
          <label for="lrf-1" class="mt-2">1</label>
          <input
            class="type-input col-8"
            type="number"
            v-model="form.lrf.one"
            id="lrf-1"
          />
        </div>
        <div class="col-1 d-flex">
          <label for="lrf-2" class="mt-2">2</label>
          <input
            class="type-input col-8"
            type="number"
            v-model="form.lrf.two"
            id="lrf-2"
          />
        </div>
        <div class="col-1 d-flex">
          <label for="lrf-3" class="mt-2">3</label>
          <input
            class="type-input col-8"
            type="number"
            v-model="form.lrf.three"
            id="lrf-3"
          />
        </div>
        <div class="col-1 d-flex">
          <label for="lrf-4" class="mt-2">4</label>
          <input
            class="type-input col-8"
            type="number"
            v-model="form.lrf.four"
            id="lrf-4"
          />
        </div>
        <div class="col-6 d-flex">
          <label for="lrf-remarks" class="mt-2">Remarks</label>
          <input
            class="type-input col-8"
            v-model="form.lrf.remarks"
            id="lrf-remarks"
          />
        </div>
      </div>

      <div class="row">
        <label class="col-2 mt-2">Cloaca / tail</label>
        <div class="col-1 d-flex">
          <label for="tail-1" class="mt-2">1</label>
          <input
            class="type-input col-8"
            type="number"
            v-model="form.tail.one"
            id="tail-1"
          />
        </div>
        <div class="col-1 d-flex">
          <label for="tail-2" class="mt-2">2</label>
          <input
            class="type-input col-8"
            type="number"
            v-model="form.tail.two"
            id="tail-2"
          />
        </div>
        <div class="col-1 d-flex">
          <label for="tail-3" class="mt-2">3</label>
          <input
            class="type-input col-8"
            type="number"
            v-model="form.tail.three"
            id="tail-3"
          />
        </div>
        <div class="col-1 d-flex">
          <label for="tail-4" class="mt-2">4</label>
          <input
            class="type-input col-8"
            type="number"
            v-model="form.tail.four"
            id="tail-4"
          />
        </div>
        <div class="col-6 d-flex">
          <label for="tail-remarks" class="mt-2">Remarks</label>
          <input
            class="type-input col-8"
            v-model="form.tail.remarks"
            id="tail-remarks"
          />
        </div>
      </div>

      <div class="row">
        <label class="col-2 mt-2">Seams / scutes</label>
        <div class="col-1 d-flex">
          <label for="scutes-1" class="mt-2">1</label>
          <input
            class="type-input col-8"
            type="number"
            v-model="form.scutes.one"
            id="scutes-1"
          />
        </div>
        <div class="col-1 d-flex">
          <label for="scutes-2" class="mt-2">2</label>
          <input
            class="type-input col-8"
            type="number"
            v-model="form.scutes.two"
            id="scutes-2"
          />
        </div>
        <div class="col-1 d-flex">
          <label for="scutes-3" class="mt-2">3</label>
          <input
            class="type-input col-8"
            type="number"
            v-model="form.scutes.three"
            id="scutes-3"
          />
        </div>
        <div class="col-1 d-flex">
          <label for="scutes-4" class="mt-2">4</label>
          <input
            class="type-input col-8"
            type="number"
            v-model="form.scutes.four"
            id="scutes-4"
          />
        </div>
        <div class="col-6 d-flex">
          <label for="scutes-remarks" class="mt-2">Remarks</label>
          <input
            class="type-input col-8"
            v-model="form.scutes.remarks"
            id="scutes-remarks"
          />
        </div>
      </div>

            <div class="row">
        <label class="col-2 mt-2">Internal</label>
        <div class="col-1 d-flex">
          <label for="internal-1" class="mt-2">1</label>
          <input
            class="type-input col-8"
            type="number"
            v-model="form.internal.one"
            id="internal-1"
          />
        </div>
        <div class="col-1 d-flex">
          <label for="internal-2" class="mt-2">2</label>
          <input
            class="type-input col-8"
            type="number"
            v-model="form.internal.two"
            id="internal-2"
          />
        </div>
        <div class="col-1 d-flex">
          <label for="internal-3" class="mt-2">3</label>
          <input
            class="type-input col-8"
            type="number"
            v-model="form.internal.three"
            id="internal-3"
          />
        </div>
        <div class="col-1 d-flex">
          <label for="internal-4" class="mt-2">4</label>
          <input
            class="type-input col-8"
            type="number"
            v-model="form.internal.four"
            id="internal-4"
          />
        </div>
        <div class="col-6 d-flex">
          <label for="internal-remarks" class="mt-2">Remarks</label>
          <input
            class="type-input col-8"
            v-model="form.internal.remarks"
            id="internal-remarks"
          />
        </div>
      </div>

    </div>
    <details
      id="edit-tumor-size-class-desc"
      class="form-wrapper card mt-2"
      open=""
    >
      <summary
        role="button"
        aria-controls="edit-tumor-size-class-desc"
        aria-expanded="true"
        aria-pressed="true"
        class="card-header"
      >
        Tumor Size class Categories
        <span class="summary"></span>
      </summary>
      <div class="card-body text-start">
        <div data-drupal-selector="edit-0">
          <ol>
            <li>Detectable patch to 1 cm diameter</li>
            <li>&gt; 1 to 4 cm</li>
            <li>&gt; 4 to 10 cm</li>
            <li>&gt; 10 cm</li>
          </ol>
        </div>
      </div>
    </details>
    <div>
      <button
        href="#"
        class="save-button btn btn-primary mt-3 mb-1"
        @click.prevent="saveTumor"
        type="submit"
      >
        Save Record
      </button>
      <br />
    </div>
  </div>
</template>

<script>
import BackButton from "@/components/BackButton.vue";

export default {
  name: "TumorForm",
  components: {
    BackButton,
  },
  mounted() {
    let encounterUUID = this.$route.params.uuid;
    if (this.$store.state.savedTumor[encounterUUID]) {
      this.form = JSON.parse(
        JSON.stringify(this.$store.state.savedTumor[encounterUUID])
      );
    } else {
      this.form.uuid = this.$route.params.uuid;
    }
  },
  data() {
    return {
      form: {
        uuid: null,
        rightEye: {
          one: "",
          two: "",
          three: "",
          four: "",
          remarks: "",
        },
        leftEye: {
          one: "",
          two: "",
          three: "",
          four: "",
          remarks: "",
        },
        mouth: {
          one: "",
          two: "",
          three: "",
          four: "",
          remarks: "",
        },
        neck: {
          one: "",
          two: "",
          three: "",
          four: "",
          remarks: "",
        },
        rff: {
          one: "",
          two: "",
          three: "",
          four: "",
          remarks: "",
        },
        lff: {
          one: "",
          two: "",
          three: "",
          four: "",
          remarks: "",
        },
        rrf: {
          one: "",
          two: "",
          three: "",
          four: "",
          remarks: "",
        },
        lrf: {
          one: "",
          two: "",
          three: "",
          four: "",
          remarks: "",
        },
        tail: {
          one: "",
          two: "",
          three: "",
          four: "",
          remarks: "",
        },
        scutes: {
          one: "",
          two: "",
          three: "",
          four: "",
          remarks: "",
        },
        internal: {
          one: "",
          two: "",
          three: "",
          four: "",
          remarks: "",
        },
      },
    };
  },
  methods: {
    saveTumor() {
      this.$store.commit("saveTumor", this.form);
      this.$router.push({
        name: "EncounterMenu",
        params: { uuid: this.form.uuid },
      });
    },
  },
};
</script>
