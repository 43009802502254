import axios from 'axios'

export default {
  /**
     * @param {string} accessToken
     * @param {string} url
     */
  fetchDropDownOptions(accessToken, url) {
    return axios.get(url, { headers: { Authorization: `Bearer ${accessToken}` } })
  },

  /**
   * @param {string} accessToken
   * @param {string} url
   * @param {string} data
   */
  pushDataToTreds(accessToken, url, fieldwork, data, process) {
    let form = new FormData();
    form.append('fieldwork', fieldwork)
    form.append('data', data)
    form.append('process', process)

    return axios.post(url, form, { headers: { Authorization: `Bearer ${accessToken}` } })
  }
  
}
