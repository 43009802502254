<template>
  <div>
    <div>
      <!-- Not using BackButton component here incase we get here by saving a form -->
      <button @click="backButtonClick" class="btn btn-secondary back-button">
        Back
      </button>
      <h3>Editing Encounter:</h3>
      <h3>
        {{
          this.$store.getters.getEncounterTitleByUUID(this.$route.params.uuid)
        }}
      </h3>
    </div>

    <router-link
      :to="{
        name: 'EncounterCoreForm',
        params: { uuid: this.$route.params.uuid },
      }"
    >
      <button class="btn btn-primary menu-button">Encounter Details</button>
    </router-link>
    <br />

    <router-link
      :to="{
        name: 'GeneticsForm',
        params: { uuid: this.$route.params.uuid },
      }"
    >
      <button class="btn btn-primary menu-button">Genetics</button>
    </router-link>

    <br />

    <router-link
      :to="{
        name: 'SatelliteForm',
        params: { uuid: this.$route.params.uuid },
      }"
    >
      <button class="btn btn-primary menu-button">Satellite Tracking</button>
    </router-link>

    <br />

    <router-link
      :to="{
        name: 'MeasurementForm',
        params: { uuid: this.$route.params.uuid },
      }"
    >
      <button class="btn btn-primary menu-button">Turtle Measurements</button>
    </router-link>

    <br />

    <router-link
      :to="{
        name: 'LaparoscopyForm',
        params: { uuid: this.$route.params.uuid },
      }"
    >
      <button class="btn btn-primary menu-button">Laparoscopy</button>
    </router-link>
    <br />

    <router-link
      :to="{
        name: 'TumorForm',
        params: { uuid: this.$route.params.uuid },
      }"
    >
    <button class="btn btn-primary menu-button">Tumor</button>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "EncounterMenu",
  methods: {
    backButtonClick() {
      if(this.$store.state.uploadFieldwork){
        this.$router.push({
            name: 'UploadValidate',
            params: { fieldwork: this.$store.state.uploadFieldwork },
          })
      } else {
        this.$router.push("/");
      }
    },
  },
};
</script>

<style>
</style>