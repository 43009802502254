<template>
  <button @click="backButtonClick" class="btn btn-secondary back-button">
    Back
  </button>
</template>

<script>
export default {
  name: "BackButton",
  methods: {
    backButtonClick() {
      this.$router.go(-1);
    },
  },
};
</script>