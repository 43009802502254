<template>
    <div>
      <BackButton />
    </div>
    <h3>Editing Measurement Details for Encounter:</h3>
    <h3>
      {{ this.$store.getters.getEncounterTitleByUUID(this.$route.params.uuid) }}
    </h3>

  <div class="inline-form measurement-form">
    <div class="encounter-form add-form">
      <h3 class="col-12 detail-title">Details</h3>

      <div class="row">
        <label class="text-end col-sm-12 col-md-4 mt-2">
          Tail - tip to plastron
        </label>
        <input
          id="tailTP"
          type="number"
          class="type-input col-8 col-md-5 me-1"
          v-model="form.tailTP"
          placeholder="Tail - tip to plastron"
        />
        <span class="cm col-1 mt-2">cm</span>
      </div>
      <div class="row">
        <label class="text-end col-sm-12 col-md-4 mt-2">
          Tail - cloaca to plastron
        </label>
        <input
          id="tailCP"
          type="number"
          class="type-input col-8 col-md-5 me-1"
          v-model="form.tailCP"
          placeholder="Tail - cloaca to plastron"
        />
        <span class="cm col-1 mt-2">cm</span>
      </div>
      <div class="row">
        <label class="text-end col-sm-12 col-md-4 mt-2">
          Tail - tip to cloaca
        </label>
        <input
          id="tailTC"
          type="number"
          class="type-input col-8 col-md-5 me-1"
          v-model="form.tailTC"
          placeholder="Tail - tip to cloaca"
        />
        <span class="cm col-1 mt-2">cm</span>
      </div>
      <div class="row">
        <label class="text-end col-sm-12 col-md-4 mt-2"> Head width </label>
        <input
          id="headWidth"
          type="number"
          class="type-input col-8 col-md-5 me-1"
          v-model="form.headWidth"
          placeholder="Head width"
        />
        <span class="cm col-1 mt-2">cm</span>
      </div>
      <div class="row">
        <label class="text-end col-sm-12 col-md-4 mt-2">
          Straight carapace length
        </label>
        <input
          id="scl"
          type="number"
          class="type-input col-8 col-md-5 me-1"
          v-model="form.scl"
          placeholder="Straight carapace length"
        />
        <span class="cm col-1 mt-2">cm</span>
      </div>
      <div class="row">
        <label class="text-end col-sm-12 col-md-4 mt-2">
          Straight carapace width
        </label>
        <input
          id="scw"
          type="number"
          class="type-input col-8 col-md-5 me-1"
          v-model="form.scw"
          placeholder="Straight carapace width"
        />
        <span class="cm col-1 mt-2">cm</span>
      </div>
      <div class="row">
        <label class="text-end col-sm-12 col-md-4 mt-2"> Carr's SCL </label>
        <input
          id="cscl"
          type="number"
          class="type-input col-8 col-md-5 me-1"
          v-model="form.cscl"
          placeholder="Carr's SCL"
        />
        <span class="cm col-1 mt-2">cm</span>
      </div>
      <div class="row">
        <label class="text-end col-sm-12 col-md-4 mt-2">
          Plastron length
        </label>
        <input
          id="plastronLength"
          type="number"
          class="type-input col-8 col-md-5 me-1"
          v-model="form.plastronLength"
          placeholder="Plastron length"
        />
        <span class="cm col-1 mt-2">cm</span>
      </div>
      <div class="row">
        <label class="text-end col-sm-12 col-md-4 mt-2"> Plastron width </label>
        <input
          id="plastronWidth"
          type="number"
          class="type-input col-8 col-md-5 me-1"
          v-model="form.plastronWidth"
          placeholder="Plastron width"
        />
        <span class="cm col-1 mt-2">cm</span>
      </div>
      <div class="row">
        <label class="text-end col-sm-12 col-md-4 mt-2"> Head length </label>
        <input
          id="headLength"
          type="number"
          class="type-input col-8 col-md-5 me-1"
          v-model="form.headLength"
          placeholder="Head length"
        />
        <span class="cm col-1 mt-2">cm</span>
      </div>
    </div>
    <div>
      <button
        href="#"
        class="save-button btn btn-primary mt-3 mb-1"
        @click.prevent="saveMeasurement"
        type="submit"
      >
        Save Record
      </button>
      <br />
    </div>
  </div>
</template>

<script>
import BackButton from "@/components/BackButton.vue";

export default {
  name: "MeasurementForm",
  components: {
    BackButton,
  },
  mounted() {
    let encounterUUID = this.$route.params.uuid;
    if (this.$store.state.savedMeasurements[encounterUUID]) {
      this.form = JSON.parse(
        JSON.stringify(this.$store.state.savedMeasurements[encounterUUID]));
    } else {
      this.form.uuid = this.$route.params.uuid;
    }
  },
  data() {
    return {
      form: {
        uuid: null,
        tailTP: "",
        tailCP: "",
        tailTC: "",
        headWidth: "",
        scl: "",
        scw: "",
        cscl: "",
        plastronLength: "",
        plastronWidth: "",
        headLength: "",
      },
    };
  },
  methods: {
    saveMeasurement() {
      this.$store.commit("saveMeasurement", this.form);
      this.$router.push({
        name: "EncounterMenu",
        params: { uuid: this.form.uuid },
      });
    },
  },
};
</script>
