<template>
  <div class="home">
    <div v-if="!this.$store.state.networkAccess">
      <div class="installBanner d-flex justify-content-center align-middle">
        Network connection unavailable, pushing to TREDS not possible
      </div>
    </div>
    <div v-else-if="this.$store.state.tredsError">
      <div
        class="installBanner d-flex justify-content-center align-middle"
        data-bs-toggle="modal"
        data-bs-target="#tredsErrorModal"
      >
        Unable to connect to TREDS, click here for more info...
      </div>
      <!-- Modal -->
      <div
        class="modal fade"
        id="tredsErrorModal"
        tabindex="-1"
        aria-labelledby="tredsErrorModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="tredsErrorModalLabel">
                Error connecting to TREDS
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div v-if="isIos">
                It appears that the TREDS site may be having issues determining
                your identity. This can be resolved by the following steps: <br><br>
                <ol class="text-start">
                  <li>Use the Backup Options button to download a backup of any saved encounters.</li>
                  <li>Delete the Data Entry App from your iPhone/iPad.</li>
                  <li>Reinstall the app from the link on <a href="https://treds.sprep.org">TREDS</a>.</li>
                  <li>Use the Backup Options button to import the backup from step 1.</li>
                </ol>
              </div>
              <div v-else>
                It appears that the TREDS site may be having issues determining
                your identity. This can be resolved by relaunching the app via
                the link on <a href="https://treds.sprep.org">TREDS</a> <br /><br />

                If this does not resolve the error, please try again later.<br />
                If the problem persists, please inform site administrator.
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <encounter-table-buttons />
  </div>
  <div class="pb-4">
    <EncounterTable />
  </div>
</template>

<script>
import EncounterTable from "@/components/EncounterTable.vue";
import EncounterTableButtons from "@/components/EncounterTableButtons.vue";

export default {
  name: "Home",
  components: { EncounterTable, EncounterTableButtons },
  data() {
    return {
      isIos: false
    }
  },
  mounted() {
    this.$store.commit("clearAllUploadArray");
    // IOS prompt to install.
    // Detects if device is on iOS
    this.isIos =
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes("Mac") && "ontouchend" in document);
  },
};
</script>
