import { createRouter, createMemoryHistory, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import EncounterCoreForm from '../views/EncounterCoreForm.vue'
import DataExport from '../views/DataExport.vue'
import EncounterMenu from '../views/EncounterMenu.vue'
import SatelliteForm from '../views/SatelliteForm.vue'
import MeasurementForm from '../views/MeasurementForm.vue'
import GeneticsForm from '../views/GeneticsForm.vue'
import LaparoscopyForm from '../views/LaparoscopyForm.vue'
import TumorForm from '../views/TumorForm.vue'
import UploadValidate from '../views/UploadValidate.vue'
import UploadData from '../views/UploadData.vue'


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/core-encounter-form',
    name: 'NewEncounterForm',
    component: EncounterCoreForm
  },
  {
    path: '/:uuid/core-encounter-form',
    name: 'EncounterCoreForm',
    component: EncounterCoreForm
  },
  {
    path: '/:uuid/satellite-form',
    name: 'SatelliteForm',
    component: SatelliteForm
  },
  {
    path: '/:uuid/tumor-form',
    name: 'TumorForm',
    component: TumorForm
  },
  {
    path: '/:uuid/measurement-form',
    name: 'MeasurementForm',
    component: MeasurementForm
  },
  {
    path: '/:uuid/genetics-form',
    name: 'GeneticsForm',
    component: GeneticsForm
  },
  {
    path: '/:uuid/laparoscopy-form',
    name: 'LaparoscopyForm',
    component: LaparoscopyForm
  },
  {
    path: '/data-export',
    name: 'DataExport',
    component: DataExport
  },
  {
    path: '/data-export/:fieldwork/validate',
    name: 'UploadValidate',
    component: UploadValidate
  },
  {
    path: '/data-export/:fieldwork/upload',
    name: 'UploadData',
    component: UploadData
  },
  {
    path: '/:uuid',
    name: 'EncounterMenu',
    component: EncounterMenu
  },
]

const router = createRouter({
  // history: createMemoryHistory(process.env.BASE_URL),
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
