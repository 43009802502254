<template>
    <div>
      <BackButton />
    </div>
    <h3>Editing Satellite Details for Encounter:</h3>
    <h3>
      {{ this.$store.getters.getEncounterTitleByUUID(this.$route.params.uuid) }}
    </h3>

  <div class="inline-form satellite-form">
    <div class="encounter-form add-form pt-3">
      <h4 class="col-12 detail-title">Details</h4>

      <div class="row">
        <label class="required text-end col-sm-12 col-md-4 mt-2"
          >Satellite Tag Number</label
        >
        <input
          id="tagNumber"
          class="type-input col-8 col-md-5"
          v-model="form.tagNumber"
          placeholder="Satellite Tag Number"
        />
      </div>

      <div class="row">
        <label class="text-end col-sm-12 col-md-4 mt-2"
          >Satellite Tag Serial Number
        </label>
        <input
          id="tagSerialNumber"
          class="type-input col-8 col-md-5"
          v-model="form.tagSerialNumber"
          placeholder="Satellite Tag Serial Number"
        />
      </div>
      <div class="row">
        <label class="text-end col-sm-12 col-md-4 mt-2">Release Date</label>
        <input
          id="releaseDate"
          class="type-input col-8 col-md-5"
          type="date"
          v-model="form.releaseDate"
        />
      </div>
      <div class="row">
        <label class="text-end col-sm-12 col-md-4 mt-2"
          >Release Time (Optional)</label
        >
        <input
          id="releaseTime"
          class="type-input col-8 col-md-5"
          type="time"
          v-model="form.releaseTime"
        />
      </div>

      <div class="row">
        <label class="text-end col-sm-12 col-md-4 mt-2"
          >Release Location Name
        </label>
        <input
          id="releaseLocationName"
          class="type-input col-8 col-md-5"
          v-model="form.releaseLocationName"
          placeholder="Release Location Name"
        />
      </div>
      <div class="row">
        <label class="text-end col-sm-12 col-md-4 mt-2">Notes</label>
        <textarea
          id="notes"
          v-model="form.notes"
          placeholder="Notes"
          class="type-input col-8 col-md-5"
        />
      </div>
    </div>
    <div class="encounter-form add-form">
      <h4 class="col-12 detail-title">Geolocation</h4>

      <div class="row">
        <label class="col-md-4 text-end">Latitude</label>
        <input
          id="latitude"
          class="type-input col-8 col-md-5"
          type="number"
          v-model="form.latitude"
          placeholder="Latitude"
        />
        <label class="col-md-4 text-end">Longitude</label>
        <input
          id="longitude"
          class="type-input col-8 col-md-5"
          type="number"
          v-model="form.longitude"
          placeholder="Longitude"
        />
      </div>
    </div>
    <div>
      <button
        href="#"
        class="save-button btn btn-primary mt-3 mb-1"
        :disabled="submitDisabled"
        @click.prevent="saveEncounter"
        type="submit"
      >
        Save Record
      </button>
      <br />
      <p class="form-error" v-if="submitDisabled">
        Please ensure all elements marked with a
        <strong class="required"> </strong> are filled
      </p>
    </div>
  </div>
</template>

<script>
import BackButton from "@/components/BackButton.vue";

export default {
  name: "SatelliteForm",
  components: {
    BackButton,
  },
  mounted() {
    let encounterUUID = this.$route.params.uuid;
    if (this.$store.state.savedSatelliteDetails[encounterUUID]) {
      this.form = JSON.parse(
        JSON.stringify(this.$store.state.savedSatelliteDetails[encounterUUID])
      );
    } else {
      this.form.uuid = this.$route.params.uuid;
    }
  },
  data() {
    return {
      form: {
        uuid: null,
        tagNumber: "",
        releaseDate: "",
        releaseTime: "",
        tagSerialNumber: "",
        releaseLocationName: "",
        longitude: "",
        latitude: "",
        notes: "",
      },
    };
  },
  computed: {
    submitDisabled() {
      return !this.form.tagNumber;
    },
  },
  methods: {
    saveEncounter() {
      this.$store.commit("saveSatelliteDetails", this.form);
      this.$router.push({
        name: "EncounterMenu",
        params: { uuid: this.form.uuid },
      });
    },
  },
};
</script>
