<template>
  <div>
    <div class="installBanner d-flex justify-content-center align-middle" v-if="updateExists" v-on:click="refreshApp">
      An update is available, click here to upgrade.
    </div>
    <button class="installBanner" v-show="this.showPWAInstallPrompt">
      <b>⚠️ Alert: </b>This app is still not installed, click here to ensure it
      is ready for offline use.
    </button>
    <div class="installBanner ios" v-show="this.showIOSInstallPrompt">
      Install the App:<br/> Tap <span v-html="iosAddSVG"></span> and then Add to Home Screen
    </div>

    <div id="nav" class="d-flex align-items-center justify-content-center">
      <img alt="Vue logo" src="./assets/turtle_icon.png" class="header-icon m-2" />
      <div><h1 class="page-title">TREDS Encounter Data Entry</h1></div>
    </div>
    <div class="main">
      <router-view />
    </div>
  </div>
  <sup>v{{ appVersion }}</sup>
</template>
<script>
import update from "@/mixins/update";
let VUE_APP_VERSION = require("../package.json").version;

export default {
  name: "App",
  components: {},
  data() {
    return {
      platform: navigator.platform,
      userAgent: navigator.userAgent,
      appVersion: VUE_APP_VERSION,
      showPWAInstallPrompt: false,
      showIOSInstallPrompt: false,
      deferredPrompt: null,
      iosAddSVG:
        '<svg class="pwa_install__ogrizok-svg" version="1.1" id="lay_1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 77.5 102.3" xml:space="preserve"><style>.st0{fill:#376eb5}</style><path class="st0" d="M65.5 102.3H12c-6.6 0-12-5.4-12-12V39.7c0-6.6 5.4-12 12-12h13v5H12c-3.8 0-7 3.1-7 7v50.5c0 3.8 3.1 7 7 7h53.5c3.8 0 7-3.1 7-7V39.7c0-3.8-3.1-7-7-7H51.2v-5h14.3c6.6 0 12 5.4 12 12v50.5c0 6.7-5.4 12.1-12 12.1z"/><path class="st0" d="M38 66.8c-1.4 0-2.5-1.1-2.5-2.5V2.5c0-1 .6-1.9 1.5-2.3.9-.4 1.9-.3 2.7.4l18 15.8c1 .9 1.1 2.5.2 3.5-.9 1-2.5 1.1-3.5.2L40.5 8v56.3c0 1.4-1.1 2.5-2.5 2.5z"/><path class="st0" d="M21 20.8c-.7 0-1.3-.3-1.8-.8-.9-1-.9-2.6.1-3.5L36.3.7c1-.9 2.6-.9 3.5.1.9 1 .9 2.6-.1 3.5l-17 15.8c-.5.5-1.1.7-1.7.7z"/></svg>',
    };
  },
  mixins: [update],
  mounted() {
    // Chrome etc support to prompt to install.
    window.addEventListener("beforeinstallprompt", (e) => {
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;
      // Update UI to notify the user they can install the PWA
      this.showPWAInstallPrompt = true;
    });

    window.addEventListener("appinstalled", () => {
      // Hide the app-provided install promotion
      this.showPWAInstallPrompt = false;
      // Clear the deferredPrompt so it can be garbage collected
      this.deferredPrompt = null;
    });

    // IOS prompt to install.
    // Detects if device is on iOS
    const isIos =
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes("Mac") && "ontouchend" in document);

    // Detects if device is in standalone mode
    const isInStandaloneMode = () =>
      "standalone" in window.navigator && window.navigator.standalone;

    // Checks if should display install popup notification:
    if (isIos && !isInStandaloneMode()) {
      //this.setState({ showInstallMessage: true });
      this.showIOSInstallPrompt = true;
      document.querySelectorAll('[rel="manifest"]')[0].setAttribute('href', '/ios_manifest.json')
    }
  },
  methods: {
    installApp() {
      if (this.deferredPrompt != undefined) {
        this.deferredPrompt.prompt();
      }
    },
  },
};
</script>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
