import { createStore } from 'vuex'
import tredsApi from '../api/tredsApi'

export default createStore({
  state: {
    accessToken: '',
    baseUrl: '',
    networkAccess: true,
    tredsError: false,
    dropdownData: {
      tagPresence: {
        placeholder: 'Tag Presence',
        path: 'reference-data/custom_allowed_values/tagPresence',
        items: []
      },
      tagType: {
        placeholder: 'Tag Type',
        path: 'reference-data/custom_allowed_values/tagType',
        items: []
      },
      tagPosition: {
        placeholder: 'Tag Position',
        path: 'reference-data/custom_allowed_values/tagPosition',
        items: []
      },
      species: {
        placeholder: 'Species',
        path: 'reference-data/term/species',
        items: [],
        allowCustomOptions: true
      },
      locality: {
        placeholder: 'Locality',
        path: 'reference-data/term/locality',
        items: [],
        allowCustomOptions: true
      },
      site: {
        placeholder: 'Site',
        path: 'reference-data/term/site',
        items: [],
        allowCustomOptions: true
      },
      ageClass: {
        placeholder: 'Age Class',
        path: 'reference-data/field_storage_config/field_age_class',
        items: []
      },
      // These will be replaced with api data once api endpoints for text list options have been set up
      observationType: {
        placeholder: 'Observation Type',
        path: 'reference-data/field_storage_config/field_observation_type',
        items: []
      },
      sex: {
        placeholder: 'Sex',
        path: 'reference-data/field_storage_config/field_sex',
        items: []
      },
      habitat: {
        placeholder: 'Habitat',
        path: 'reference-data/field_storage_config/field_habitat',
        items: []
      },
      encounterType: {
        placeholder: 'Encounter Type',
        path: 'reference-data/term/encounter_type',
        items: []
      },
      taggingStatus: {
        path: 'reference-data/field_storage_config/field_tagging_status',
        placeholder: 'Tagging Status',
        items: []
      },
      encounterActivity: {
        placeholder: 'Activity',
        path: 'reference-data/field_storage_config/field_activity',
        items: []
      },
      encounterCondition: {
        placeholder: 'Condition',
        path: 'reference-data/term/condition',
        items: []
      },
      windDirection: {
        placeholder: 'Wind Direction',
        path: 'reference-data/field_storage_config/field_wind_direction',
        items: []
      },
      windStrength: {
        placeholder: 'Wind Strength',
        path: 'reference-data/field_storage_config/field_wind_strength',
        items: []
      },
      cloudCover: {
        placeholder: 'Cloud Cover',
        path: 'reference-data/field_storage_config/field_cloud_cover',
        items: []
      },
      seaConditions: {
        placeholder: 'Sea Conditions',
        path: 'reference-data/field_storage_config/field_sea_conditions',
        items: []
      },
      rain: {
        placeholder: 'Rain',
        path: 'reference-data/field_storage_config/field_rain',
        items: []
      },
      waterDepth: {
        placeholder: 'Water Depth',
        path: 'reference-data/field_storage_config/field_water_depth',
        items: []
      },
      bottomComposition: {
        placeholder: 'Bottom Composition',
        path: 'reference-data/field_storage_config/field_bottom_composition',
        items: []
      },
      shorelineType: {
        placeholder: 'Shoreline Type',
        path: 'reference-data/field_storage_config/field_shoreline_type',
        items: []
      },
      uniqueEncounterDetails: {
        placeholder: 'Unique Encounter Details',
        path: 'reference-data/field_storage_config/field_unique_encounter_detail',
        items: []
      },
      geneticsMaterialSampled: {
        placeholder: 'Material Sampled',
        path: 'reference-data/field_storage_config/field_material_sampled',
        items: []
      },
      geneticsSampleLocation: {
        placeholder: 'Sample Location',
        path: 'reference-data/field_storage_config/field_sample_location',
        items: []
      },
      otherSampling: {
        placeholder: 'Sample Location',
        path: 'reference-data/field_storage_config/field_other_sampling',
        items: []
      },
      samplesCollectedFor: {
        placeholder: 'Sample Location',
        path: 'reference-data/field_storage_config/field_samples_collected',
        items: []
      }
    },
    createdDropdownOptions: {
      species: {},
      locality: {},
      site: {}
    },
    dataFormatVersion: '1.1.0',
    savedEncounters: {},
    savedSatelliteDetails: {},
    savedMeasurements: {},
    savedGenetics: {},
    savedLaparoscopy: {},
    savedTumor: {},
    uploadArray: [],
    uploadFieldwork: null
  },
  getters: {
    getEncounterTitleByUUID: (state) => (uuid) => {
      let encounter = JSON.parse(JSON.stringify(state.savedEncounters[uuid]));

      let tagNumArray = []
      encounter.tags.forEach((element) => {
        tagNumArray.push(element.tagNumber)
      })

      let tagString = tagNumArray.join(', ')

      let speciesString = encounter.species ? encounter.species.name : ' '
      let sexString = encounter.sex ? encounter.sex.name.split(' ').at(-1) : ' '
      let dateString = new Date(encounter.encounterDate).toLocaleDateString("en-AU", { year: 'numeric', month: 'short', day: 'numeric' })
      let title = [tagString, speciesString, sexString, dateString].join(' - ');
      if (encounter.uploaded) {
        title += " (UPLOADED)"
      }
      return title
    },
    encountersFromUploadArray(state) {
      let encounters = {};
      state.uploadArray.forEach(uuid => {
        encounters[uuid] = state.savedEncounters[uuid]
      });
      return encounters
    }
  },
  mutations: {
    setDropdownData(state, payload) {
      state.dropdownData[payload.listId].items = payload.data
    },
    setCreatedOptions(state, data) {
      state.createdDropdownOptions = data
    },
    setAllEncounters(state, data) {
      state.savedEncounters = data
      localStorage.setItem('savedEncounters', JSON.stringify(state.savedEncounters))
    },
    setAllSatelliteDetails(state, data) {
      state.savedSatelliteDetails = data
      localStorage.setItem('savedSatelliteDetails', JSON.stringify(state.savedSatelliteDetails))
    },
    setAllMeasurements(state, data) {
      state.savedMeasurements = data
      localStorage.setItem('savedMeasurements', JSON.stringify(state.savedMeasurements))

    },
    setAllGenetics(state, data) {
      state.savedGenetics = data
      localStorage.setItem('savedGenetics', JSON.stringify(state.savedGenetics))

    },
    setAllLaparoscopy(state, data) {
      state.savedLaparoscopy = data
      localStorage.setItem('savedLaparoscopy', JSON.stringify(state.savedLaparoscopy))

    },
    setAllTumor(state, data) {
      state.savedTumor = data
      localStorage.setItem('savedTumor', JSON.stringify(state.savedTumor))

    },
    setupApiConfig(state) {
      state.accessToken = localStorage.getItem('api-token') ? localStorage.getItem('api-token') : ''
      state.baseUrl = localStorage.getItem('base-url') ? localStorage.getItem('base-url') : ''
    },
    addCustomDropdownOption(state, { listId, name, value }) {
      state.createdDropdownOptions[listId] = (typeof state.createdDropdownOptions[listId] !== 'undefined' && state.createdDropdownOptions[listId] instanceof Array) ? state.createdDropdownOptions[listId] : []
      state.createdDropdownOptions[listId].push({ name: name, value: value })
      state.dropdownData[listId].items.push({ value: value, name: name })
      localStorage.setItem('createdDropdownOptions', JSON.stringify(state.createdDropdownOptions))
    },
    deleteEncounter(state, uuid) {
      delete state.savedEncounters[uuid]

      // Send a copy of the encounter object to data store.
      localStorage.setItem('savedEncounters', JSON.stringify(state.savedEncounters))
    },
    deleteSatelliteDetails(state, uuid) {
      delete state.savedSatelliteDetails[uuid]
      localStorage.setItem('savedSatelliteDetails', JSON.stringify(state.savedSatelliteDetails))
    },
    deleteMeasurementDetails(state, uuid) {
      delete state.savedMeasurements[uuid]
      localStorage.setItem('savedMeasurements', JSON.stringify(state.savedMeasurements))
    },
    deleteGenetics(state, uuid) {
      delete state.savedGenetics[uuid]
      localStorage.setItem('savedGenetics', JSON.stringify(state.savedGenetics))
    },
    deleteLaparoscopy(state, uuid) {
      delete state.savedLaparoscopy[uuid]
      localStorage.setItem('savedLaparoscopy', JSON.stringify(state.savedLaparoscopy))
    },
    deleteTumor(state, uuid) {
      delete state.savedTumor[uuid]
      localStorage.setItem('savedTumor', JSON.stringify(state.savedTumor))
    },
    saveEncounter(state, encounter) {
      // Send a copy of the encounter object to data store.
      state.savedEncounters[encounter.uuid] = JSON.parse(JSON.stringify(encounter))
      localStorage.setItem('savedEncounters', JSON.stringify(state.savedEncounters))
    },
    saveSatelliteDetails(state, satelliteDetails) {
      // Send a copy of the encounter object to data store.
      state.savedSatelliteDetails[satelliteDetails.uuid] = JSON.parse(JSON.stringify(satelliteDetails))
      localStorage.setItem('savedSatelliteDetails', JSON.stringify(state.savedSatelliteDetails))
    },
    saveMeasurement(state, measurement) {
      // Send a copy of the encounter object to data store.
      state.savedMeasurements[measurement.uuid] = JSON.parse(JSON.stringify(measurement))
      localStorage.setItem('savedMeasurements', JSON.stringify(state.savedMeasurements))
    },
    saveGenetics(state, genetics) {
      // Send a copy of the encounter object to data store.
      state.savedGenetics[genetics.uuid] = JSON.parse(JSON.stringify(genetics))
      localStorage.setItem('savedGenetics', JSON.stringify(state.savedGenetics))
    },
    saveLaparoscopy(state, laparoscopy) {
      // Send a copy of the encounter object to data store.
      state.savedLaparoscopy[laparoscopy.uuid] = JSON.parse(JSON.stringify(laparoscopy))
      localStorage.setItem('savedLaparoscopy', JSON.stringify(state.savedLaparoscopy))
    },
    saveTumor(state, tumor) {
      // Send a copy of the encounter object to data store.
      state.savedTumor[tumor.uuid] = JSON.parse(JSON.stringify(tumor))
      localStorage.setItem('savedTumor', JSON.stringify(state.savedTumor))
    },
    clearAllEncounters(state) {
      state.savedEncounters = {}
      localStorage.setItem('savedEncounters', JSON.stringify(state.savedEncounters))
      state.savedSatelliteDetails = {}
      localStorage.setItem('savedSatelliteDetails', JSON.stringify(state.savedSatelliteDetails))
      state.savedMeasurements = {}
      localStorage.setItem('savedMeasurements', JSON.stringify(state.savedMeasurements))
      state.savedGenetics = {}
      localStorage.setItem('savedGenetics', JSON.stringify(state.savedGenetics))
      state.savedLaparoscopy = {}
      localStorage.setItem('savedLaparoscopy', JSON.stringify(state.savedLaparoscopy))
      state.savedTumor = {}
      localStorage.setItem('savedTumor', JSON.stringify(state.savedTumor))
    },
    online(state) {
      state.networkAccess = true
    },
    offline(state) {
      state.networkAccess = false
    },
    tredsError(state, value) {
      state.tredsError = value
    },
    setUploadDetails(state, payload) {
      state.uploadArray = payload.uploadArray;
      state.uploadFieldwork = payload.fieldwork
    },
    markEncounterUploaded(state, uuid) {
      state.savedEncounters[uuid].uploaded = true
      localStorage.setItem('savedEncounters', JSON.stringify(state.savedEncounters))

      state.uploadArray.splice(state.uploadArray.indexOf(uuid), 1);
    },
    clearAllUploadArray(state) {
      state.uploadArray = [];
      state.uploadFieldwork = null;
    },
    updateDataFormatBeforeLoad(context) {
      /* eslint-disable */
      let format = localStorage.getItem('dataFormatVersion')

      /*
       We never break here, instead falling open through all cases.
       This ensures the datastore has all updates applied, through to the most
       recent, starting at the point where the database was last saved.
      */
      switch (format) {
        case undefined:
          // If format is undefined, must be first load, so no data to transform
          break;
          case '1.0.0': 
            let genetics = JSON.parse(localStorage.getItem('savedGenetics'))
            for(const [key, value] of Object.entries(genetics)) {
              genetics[key].otherSampling = []
              genetics[key].samplesCollectedFor = []
            }
            localStorage.setItem('savedGenetics', JSON.stringify(genetics))
        /*
        Demonstration for potential updating of format:
         case '1.0.0':
          let tumorData = localStorage.getItem('tummor')
          localStorage.setItem('tumor', tumorData)
          localStorage.removeItem('tummor')
        */
      }
      localStorage.setItem('dataFormatVersion', context.dataFormatVersion)
      /* eslint-enable */
    },
  },
  actions: {
    // Load all data from localStorage on inital load
    initialSetup(context) {
      let params = new URLSearchParams(window.location.search.substring(1));
      let token = params.get("token") || false;
      let baseUrl = params.get("base_url") || false;

      if (token !== false) {
        localStorage.setItem('api-token', token)
      }

      if (baseUrl !== false) {
        localStorage.setItem('base-url', baseUrl)
      }

      context.commit('setupApiConfig')

      // Prevents setting Vuex createdDropdownOptions object to null when loading an empty object
      var customOptionsFromStorage = JSON.parse(localStorage.getItem('createdDropdownOptions'))
      if (customOptionsFromStorage != null) {
        context.commit('setCreatedOptions', customOptionsFromStorage)
      }

      // dataFormatVersion is incremented each time the structure of saved data is changed.
      // If the format matches, data can be loaded directly from storage, via loadSavedDataFromCurrentFormat
      // If format doesn't match, data must be loaded from storage, tranformed in some way, and then saved back into storage.
      let savedFormat = localStorage.getItem('dataFormatVersion')
      if(savedFormat !== context.state.dataStructureVersion) {
        context.commit('updateDataFormatBeforeLoad')
      }

      // Prevents setting Vuex savedEncounter object to null when loading an empty array
      var encountersFromStorage = JSON.parse(localStorage.getItem('savedEncounters') || '{}')
      if (encountersFromStorage != null) {
        context.commit('setAllEncounters', encountersFromStorage)
      }

      // Prevents setting Vuex savedSatelliteDetails object to null when loading an empty array
      var satelliteDetailsFromStorage = JSON.parse(localStorage.getItem('savedSatelliteDetails') || '{}')
      if (satelliteDetailsFromStorage != null) {
        context.commit('setAllSatelliteDetails', satelliteDetailsFromStorage)
      }

      // Prevents setting Vuex savedMeasurements object to null when loading an empty array
      var measurementsFromStorage = JSON.parse(localStorage.getItem('savedMeasurements') || '{}')
      if (measurementsFromStorage != null) {
        context.commit('setAllMeasurements', measurementsFromStorage)
      }

      // Prevents setting Vuex savedGenetics object to null when loading an empty array
      var geneticsFromStorage = JSON.parse(localStorage.getItem('savedGenetics') || '{}')
      if (geneticsFromStorage != null) {
        context.commit('setAllGenetics', geneticsFromStorage)
      }

      // Prevents setting Vuex savedLaparoscopy object to null when loading an empty array
      var laparoscopyFromStorage = JSON.parse(localStorage.getItem('savedLaparoscopy') || '{}')
      if (laparoscopyFromStorage != null) {
        context.commit('setAllLaparoscopy', laparoscopyFromStorage)
      }

      // Prevents setting Vuex savedTumor object to null when loading an empty array
      var tumorFromStorage = JSON.parse(localStorage.getItem('savedTumor') || '{}')
      if (tumorFromStorage != null) {
        context.commit('setAllTumor', tumorFromStorage)
      }

      if (navigator.onLine) {
        context.dispatch('updateDataFromApi')
      } else {
        context.dispatch('loadDataFromLocalStorage')
      }
    },
    loadDataFromLocalStorage(context) {
      Object.keys(context.state.dropdownData).forEach(index => {
        let element = context.state.dropdownData[index]
        context.commit({
          type: 'setDropdownData',
          listId: index,
          data: JSON.parse(localStorage.getItem(index))
        })
      })
    },
    updateDataFromApi(context) {
      let allFetches = []
      let fetchError = false
      Object.keys(context.state.dropdownData).forEach(index => {
        let element = context.state.dropdownData[index]

        let fetch = tredsApi.fetchDropDownOptions(context.state.accessToken, context.state.baseUrl + '/' + element.path)
          .then(function (response) {
            // handle success
            if (response.status === 200) {
              /** @param {Object[]} */
              let options = response.data
              if(typeof response.data !== "object") {
                throw new Error("Non-json response")
              }
              if (element.allowCustomOptions && (context.state.createdDropdownOptions[index].length > 0)) {
                options = options.concat(JSON.parse(JSON.stringify(context.state.createdDropdownOptions[index])))
              }
              context.commit({
                type: 'setDropdownData',
                listId: index,
                data: options
              })
              localStorage.setItem(index, JSON.stringify(options))
            }
          })
          .catch(function (error) {
            // handle error
            console.log(error)
            fetchError = true
          })
          allFetches.push(fetch)
      })
      Promise.allSettled(allFetches).then(() => {
        if(fetchError) {
          context.dispatch('loadDataFromLocalStorage')
          // Don't blame treds for networking error if offline
          if(context.state.networkAccess) {
            context.commit('tredsError', true)
          }
        }
      })
    },
    clearUploadedEncounters(context) {
      Object.values(context.state.savedEncounters).forEach(encounter => {
        if (encounter.uploaded) {
          context.commit("deleteSatelliteDetails", encounter.uuid);
          context.commit("deleteMeasurementDetails", encounter.uuid);
          context.commit("deleteGenetics", encounter.uuid);
          context.commit("deleteLaparoscopy", encounter.uuid);
          context.commit("deleteTumor", encounter.uuid);
          context.commit("deleteEncounter", encounter.uuid);
        }
      })
    }
  },
})
