<template>
  <div v-if="!this.error && this.$store.state.networkAccess" class="col p-3">
    <div class="mb-4">
      <!-- Not using BackButton component here incase we get here by saving a form -->
      <button
        @click="
          this.$router.push({
            name: 'DataExport',
          })
        "
        class="btn btn-secondary back-button"
      >
        Back
      </button>
      <h3>Data Validation</h3>
    </div>
    <br />
    <div
      class="result-table"
      v-if="Object.keys(this.$store.state.savedEncounters).length > 0"
    >
      <table class="table table-striped table-bordered">
        <thead class="bg-light">
          <th></th>
          <th>Encounter</th>
          <th>Status</th>
        </thead>
        <tbody>
          <tr
            v-for="encounter in this.$store.getters.encountersFromUploadArray"
            :key="encounter.uuid"
          >
            <td p-3>
              <router-link
                :to="{
                  name: 'EncounterMenu',
                  params: { uuid: encounter.uuid },
                }"
              >
                <button class="btn btn-sm btn-outline-primary">✎</button>
              </router-link>
            </td>
            <td>
              {{ this.$store.getters.getEncounterTitleByUUID(encounter.uuid) }}
            </td>
            <td>
              <div v-if="loading" class="spinner-border" role="status"></div>
              <svg
                v-else-if="
                  validationStatus && validationStatus[encounter.uuid]['valid']
                "
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"
                />
              </svg>
              <div v-else>
              <svg
              class="m-2"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z"
                />
              </svg>
                <ul>
                  <li class="text-start" v-for="error in this.validationStatus[encounter.uuid].messages" v-bind:key="error">
                    <p v-html="error.replace('placeholder','')">
                    </p>
                  </li>
                </ul>

              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="row justify-content-center">
        <button
          @click="
            this.$router.push({
              name: 'UploadData',
              params: { fieldwork: this.$route.params.fieldwork },
            })
          "
          :disabled="invalidEncounters || waitingValidation > 0"
          class="btn btn-primary m-3 col-3"
        >
          Complete Data Push
        </button>
        <p>Data push cannot be completed until all data is valid</p>
      </div>
    </div>
  </div>
  <div v-else class="py-3">
    <h2>A network error has occured.</h2>
    <br />
    <p>
      Please click below to return to the homepage and try again later.
    </p>
    <router-link to="/">
      <button class="btn btn-lg btn-danger">Return home</button>
    </router-link>
  </div>
</template>

<script>
import tredsApi from "../api/tredsApi";

export default {
  name: "UploadValidate",
  components: {},
  data() {
    return {
      loading: true,
      error: false,
      validationStatus: {},
      waitingValidation: 0
    };
  },
  mounted() {
    let uuids = this.$store.state.uploadArray;

    this.waitingValidation = uuids.length


     const asyncLimit = (fn, n) => {
      const pendingPromises = new Set();
      return async function (...args) {
        while (pendingPromises.size >= n) {
          await Promise.race(pendingPromises);
        }

        const p = fn.apply(this, args);
        const r = p.catch(() => {});
        pendingPromises.add(r);
        await r;
        pendingPromises.delete(r);
        return p;
      };
    };

    let limitedValidate = asyncLimit(this.checkValidation, 10);


    uuids.forEach((element) => {
      limitedValidate(element);
    });
  },
  methods: {
    checkValidation(uuid) {
      this.loading = true;
      let url = this.$store.state.baseUrl + "/data-entry-upload";

      let data = {};
      data.encounter = this.$store.state.savedEncounters[uuid] ?? {};
      data.satellite = this.$store.state.savedSatelliteDetails[uuid]  ?? {};
      data.measurement = this.$store.state.savedMeasurements[uuid] ?? {};
      data.genetics = this.$store.state.savedGenetics[uuid] ?? {};
      data.laparoscopy = this.$store.state.savedLaparoscopy[uuid] ?? {};
      data.tumor = this.$store.state.savedTumor[uuid] ?? {};

      return tredsApi
        .pushDataToTreds(
          this.$store.state.accessToken,
          url,
          this.$route.params.fieldwork,
          JSON.stringify(data),
          "validate"
        )
        .then((result) => {
          this.validationStatus[uuid] = result.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = true;
        })
        .then(() => {
          this.loading = false;
          this.waitingValidation--;
        });
    },
  },
  computed: {
    invalidEncounters() {
      if (!this.validationStatus) {
        return true;
      }
      for (const encounter of Object.values(this.validationStatus)) {
        if (!encounter["valid"]) {
          return true;
        }
      }
      return false;
    },
  },
};
</script>