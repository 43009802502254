<template>
  <div v-if="!this.error && this.$store.state.networkAccess" class="col p-3">
    <div class="mb-4">
      <!-- Not using BackButton component here incase we get here by saving a form -->
      <button
        @click="this.$router.push('/')"
        class="btn btn-secondary back-button"
      >
        Back
      </button>
      <h3>Push Data to TREDS</h3>
      <div v-if="loading" class="spinner-border" role="status"></div>
    </div>
    <div class="row">
      <p>Select fieldwork to push encounter data to:</p>
      <div class="col-sm-6">
        <h4>Country</h4>
        <VueMultiselect
          :disabled="loading"
          v-model="country"
          :options="countryOptions"
          label="name"
          track-by="name"
        />
      </div>
      <div class="col-sm-6">
        <h4>Project</h4>
        <VueMultiselect
          :disabled="loading || projectOptions.length == 0"
          v-model="project"
          :options="projectOptions"
          label="name"
          track-by="name"
        />
      </div>
      <div class="col">
        <h4>Fieldwork</h4>
        <VueMultiselect
          :disabled="loading || fieldworkOptions.length == 0"
          v-model="fieldwork"
          :options="fieldworkOptions"
          label="name"
          track-by="name"
        />
      </div>
    </div>
    <br />
    <div
      class="result-table"
      v-if="encounterList.length > 0"
    >
      <h1>Saved Encounters</h1>
      <p>Select encounter data to push:</p>
      <table class="table table-striped table-bordered">
        <thead class="bg-light">
          <th>
            <input
              class="form-check-input fs-5 select-all-check"
              type="checkbox"
              :checked="isAllSelected"
              @change="selectAll"
            />
          </th>
          <th>Encounter</th>
        </thead>
        <tbody>
          <tr v-for="encounter in encounterList" :key="encounter.uuid">
            <td>
              <input
                class="form-check-input"
                type="checkbox"
                :value="encounter.uuid"
                v-model="selectedEncounters"
              />
            </td>
            <td>
              {{ this.$store.getters.getEncounterTitleByUUID(encounter.uuid) }}
            </td>
          </tr>
        </tbody>
      </table>
      <button
        @click.prevent="uploadEncounters"
        :disabled="fieldwork == null || selectedEncounters.length == 0"
        class="btn btn-primary m-3"
      >
        Push Selected Encounters
      </button>
    </div>
  </div>
  <div v-else class="py-3">
    <h2>A network error has occured.</h2>
    <br />
    <h4>
      Please click below to return to the homepage and try again when network
      access has been restored.
    </h4>
    <router-link to="/">
      <button class="btn btn-lg btn-danger">Return home</button>
    </router-link>
  </div>
</template>

<script>
import tredsApi from "../api/tredsApi";
import VueMultiselect from "vue-multiselect";

export default {
  name: "DataExport",
  components: {
    VueMultiselect,
  },
  data() {
    return {
      loading: false,
      error: false,
      country: null,
      project: null,
      fieldwork: null,
      selectedEncounters: [],
      countryOptions: [],
      projectOptions: [],
      fieldworkOptions: [],
    };
  },
  watch: {
    country(newValue) {
      this.project = null;
      this.fieldwork = null;
      this.projectOptions = [];
      this.fieldworkOptions = [];
      if (newValue) {
        this.loading = true;
        tredsApi
          .fetchDropDownOptions(
            this.$store.state.accessToken,
            this.$store.state.baseUrl +
              "/reference-data/projects-by-country/" +
              newValue.value
          )
          .then((result) => {
            this.projectOptions = result.data;
          })
          .catch((error) => {
            console.error("Error fetching projects. More details to follow...");
            console.log(error);
            this.error = true;
          })
          .then(() => {
            this.loading = false;
          });
      }
    },
    project(newValue) {
      this.fieldwork = null;
      this.fieldworkOptions = [];
      if (newValue) {
        this.loading = true;
        tredsApi
          .fetchDropDownOptions(
            this.$store.state.accessToken,
            this.$store.state.baseUrl +
              "/reference-data/fieldworks-by-project/" +
              newValue.value
          )
          .then((result) => {
            this.fieldworkOptions = result.data;
          })
          .catch((error) => {
            console.error(
              "Error fetching fieldwork. More details to follow..."
            );
            console.log(error);
            this.error = true;
          })
          .then(() => {
            this.loading = false;
          });
      }
    },
  },
  mounted() {
    this.loading = true;
    let url = this.$store.state.baseUrl + "/reference-data/term/country";

    tredsApi
      .fetchDropDownOptions(this.$store.state.accessToken, url)
      .then((result) => {
        this.countryOptions = result.data;
      })
      .catch((error) => {
        console.error("Error fetching country list. More details to follow...");
        console.log(error);
        this.error = true;
      })
      .then(() => {
        this.loading = false;
      });
  },
  methods: {
    uploadEncounters() {
      let url = this.$store.state.baseUrl + "/data-entry-upload";
      let token = this.$store.state.accessToken;

      let data = {};
      data.encounters = this.$store.state.savedEncounters;
      data.satellite = this.$store.state.savedSatelliteDetails;
      data.measurement = this.$store.state.savedMeasurements;
      data.genetics = this.$store.state.savedGenetics;
      data.laparoscopy = this.$store.state.savedLaparoscopy;
      data.tumor = this.$store.state.savedTumor;

      this.$store.commit("setUploadDetails", {
        uploadArray: this.selectedEncounters,
        fieldwork: this.fieldwork.value,
      });
      this.$router.push({
        name: "UploadValidate",
        params: { fieldwork: this.fieldwork.value },
      });
    },
    selectAll(event) {
      if (event.target.checked) {
        this.selectedEncounters = []
        this.encounterList.forEach(encounter => {
          this.selectedEncounters.push(encounter.uuid)
        });
      } else {
        this.selectedEncounters = [];
      }
    },
  },
  computed: {
    encounterList() {
      return Object.values(this.$store.state.savedEncounters).filter((encounter) => !encounter.uploaded)
    },
    isAllSelected() {
      return this.encounterList.length === this.selectedEncounters.length;
    },
  },
};
</script>
