<template>
  <div
    class="d-flex flex-column flex-sm-row gap-3 justify-content-center p-2"
    role="group"
  >
    <button
      class="btn btn-primary"
      @click.prevent="this.$router.push({ name: 'NewEncounterForm' })"
      role="button"
    >
      Add New Encounter
    </button>
    <button
      class="btn btn-primary"
      @click="this.$router.push('/data-export')"
      v-if="Object.keys(this.$store.state.savedEncounters).length > 0"
      :disabled="
        !this.$store.state.networkAccess || this.$store.state.tredsError
      "
      role="button"
    >
      Push Data to TREDS
    </button>
    <div class="btn-group" role="group">
      <button
        id="btnDropBackup"
        type="button"
        class="btn btn-primary dropdown-toggle"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        Backup Options
      </button>
      <ul class="dropdown-menu" aria-labelledby="btnDropBackup">
        <li>
          <a class="dropdown-item" @click.prevent="exportBackup"
            >Export Backup</a
          >
        </li>
        <li>
          <a
            class="dropdown-item"
            href="#"
            data-bs-toggle="modal"
            data-bs-target="#importModal"
          >
            Import Backup</a
          >
        </li>
      </ul>
    </div>

    <div
      class="btn-group"
      role="group"
      v-if="Object.keys(this.$store.state.savedEncounters).length > 0"
    >
      <button
        id="btnGroupDrop1"
        type="button"
        class="btn btn-primary dropdown-toggle"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        Encounter Options
      </button>
      <ul class="dropdown-menu" aria-labelledby="btnGroupDrop1">
        <li>
          <a class="dropdown-item" @click="clearAll">Clear All Encounters</a>
        </li>
        <li>
          <a class="dropdown-item" @click="clearUploaded"
            >Clear Pushed Encounters</a
          >
        </li>
      </ul>
    </div>
  </div>

  <!-- Modal -->
  <div
    class="modal fade"
    id="importModal"
    tabindex="-1"
    aria-labelledby="importModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="importModalLabel">Import from Backup</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="alert alert-danger" role="alert">
            WARNING: Importing from a backup will completely overwrite all
            existing data.
          </div>
          <span>Select the <code>.json</code> file to import.</span>
          <input type="file" name="importUpload" id="importInput" />
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="button"
            data-bs-dismiss="modal"
            @click="importFromFile"
            class="btn btn-primary"
          >
            Confirm Import
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "EncounterTableButtons",
  methods: {
    clearAll() {
      if (confirm("Are you sure you want to delete all saved encounters?")) {
        this.$store.commit("clearAllEncounters");
      }
    },
    clearUploaded() {
      if (
        confirm(
          "Are you sure you want to delete all encounters that have been uploaded?"
        )
      ) {
        this.$store.dispatch("clearUploadedEncounters");
      }
    },
    deleteRecord(uuid) {
      if (confirm("Are you sure you want to delete this encounter?")) {
        this.$store.commit("deleteEncounter", uuid);
        this.$store.commit("deleteSatelliteDetails", uuid);
        this.$store.commit("deleteMeasurementDetails", uuid);
        this.$store.commit("deleteGenetics", uuid);
        this.$store.commit("deleteLaparoscopy", uuid);
        this.$store.commit("deleteTumor", uuid);
      }
    },
    exportBackup() {
      let data = {};
      data.backupVersion = require("../../package.json").version;
      data.encounters = this.$store.state.savedEncounters;
      data.satellite = this.$store.state.savedSatelliteDetails;
      data.measurement = this.$store.state.savedMeasurements;
      data.genetics = this.$store.state.savedGenetics;
      data.laparoscopy = this.$store.state.savedLaparoscopy;
      data.tumor = this.$store.state.savedTumor;

      var element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," +
          encodeURIComponent(JSON.stringify(data))
      );
      element.setAttribute("download", "treds_encounters.json");

      element.style.display = "none";
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    },
    importFromFile() {
      const target = document.getElementById("importInput");
      const file = target.files[0];
      if (!file) {
        return;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        const contents = e.target.result;
        let importedData = JSON.parse(contents);
        switch (importedData.backupVersion) {
          case "1.1.0":
            // The data is in the format of v1.1.0
            this.$store.commit("setAllEncounters", importedData.encounters);
            this.$store.commit("setAllGenetics", importedData.genetics);
            this.$store.commit("setAllLaparoscopy", importedData.laparoscopy);
            this.$store.commit("setAllMeasurements", importedData.measurement);
            this.$store.commit(
              "setAllSatelliteDetails",
              importedData.satellite
            );
            this.$store.commit("setAllTumor", importedData.tumor);
            break;
          case "1.0.0":
            // The data is in the format of v1.0.0
            this.$store.commit("setAllEncounters", importedData.encounters);
            for (const key of Object.keys(importedData.genetics)) {
              importedData.genetics[key].otherSampling = [];
              importedData.genetics[key].samplesCollectedFor = [];
            }
            this.$store.commit("setAllGenetics", importedData.genetics);
            this.$store.commit("setAllLaparoscopy", importedData.laparoscopy);
            this.$store.commit("setAllMeasurements", importedData.measurement);
            this.$store.commit(
              "setAllSatelliteDetails",
              importedData.satellite
            );
            this.$store.commit("setAllTumor", importedData.tumor);
            break;
          case undefined:
            // The data is in the format of the original data entry app.
            this.$store.commit("setAllEncounters", {});
            Object.values(importedData.encounters).forEach((encounter) => {
              encounter.tags.forEach((tag, index) => {
                encounter.tags[index].tagNumber =
                  encounter.tags[index].tag_number;
                encounter.tags[index].tagPresence =
                  encounter.tags[index].tag_presence;
                encounter.tags[index].tagType = encounter.tags[index].tag_type;
                encounter.tags[index].tagPosition =
                  encounter.tags[index].tag_position;
                encounter.tags[index].taggerName =
                  encounter.tags[index].tagger_name;
                encounter.tags[index].appliedDate =
                  encounter.tags[index].applied_data;
              });
              this.$store.commit("saveEncounter", encounter);
            });
            this.$store.commit("setAllGenetics", {});
            this.$store.commit("setAllLaparoscopy", {});
            this.$store.commit("setAllMeasurements", {});
            this.$store.commit("setAllSatelliteDetails", {});
            this.$store.commit("setAllTumor", {});
            break;
        }
      };
      target.value = "";
      reader.readAsText(file);
    },
  },
};
</script>
