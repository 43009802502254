<template>
    <div>
      <BackButton />
    </div>
    <h3>Editing Laparoscopy Details for Encounter:</h3>
    <h3>
      {{ this.$store.getters.getEncounterTitleByUUID(this.$route.params.uuid) }}
    </h3>

  <div class="inline-form laparoscopy-from">
    <div class="row mt-3">
      <label class="text-end col-sm-12 col-md-4 mt-2">Research Method</label>
      <select class="type-input col-8 col-md-5" v-model="form.researchMethod">
        <option
          v-for="option in researchMethodOptions"
          v-bind:value="option.value"
          v-bind:key="option.value"
        >
          {{ option.text }}
        </option>
      </select>
    </div>
    <div class="row mt-3">
      <label class="text-end col-sm-12 col-md-4 mt-2">Researcher</label>
      <input class="type-input col-8 col-md-5" v-model="form.researcher" />
    </div>
    <div class="encounter-form add-form my-4">
      <h4 class="detail-title p-2">Female</h4>

      <div class="row">
        <label class="text-end col-sm-12 col-md-4 mt-2"
          >Previtellogenic follicles</label
        >
        <select
          class="type-input col-8 col-md-5"
          v-model="form.stromaPrevitellogenicFollicles"
        >
          <option
            v-for="option in stromaPrevitellogenicFolliclesOptions"
            v-bind:value="option.value"
            v-bind:key="option.value"
          >
            {{ option.text }}
          </option>
        </select>
      </div>

      <div class="row d-flex justify-content-evenly female-wrapper">
        <div class="laparoscopy-block-wrapper developing-follicles col-md-5">
          <h4 class="col-12 laparoscopy-block-title">Developing follicles</h4>

          <div class="row">
            <div class="d-flex flex-column align-items-center col-6 my-2">
              <label class="text-end">Diameter</label>
              <input
                id="tagNumber"
                class="type-input col-md-10"
                v-model="form.developingFolliclesDiameter"
                type="number"
              />
            </div>
            <div class="d-flex flex-column align-items-center col-6 my-2">
              <label class="text-end">Colour</label>
              <select
                class="type-input col-md-10"
                v-model="form.developingFolliclesColour"
              >
                <option
                  v-for="option in colourOptions"
                  v-bind:value="option.value"
                  v-bind:key="option.value"
                >
                  {{ option.text }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="laparoscopy-block-wrapper mature-follicles col-md-5">
          <h4 class="col-12 laparoscopy-block-title">Mature follicles</h4>

          <div class="row">
            <div class="d-flex flex-column align-items-center col-4 my-2">
              <label class="text-end">Diameter</label>
              <input
                id="tagNumber"
                class="type-input col-10"
                v-model="form.matureFolliclesDiameter"
                type="number"
              />
            </div>
            <div class="d-flex flex-column align-items-center col-4 my-2">
              <label class="text-end">Abundance</label>
              <input
                id="tagNumber"
                class="type-input col-10"
                v-model="form.matureFolliclesAbundance"
                type="number"
              />
            </div>
            <div class="d-flex flex-column align-items-center col-4 my-2">
              <label class="text-end">Colour</label>
              <select
                class="type-input col-10"
                v-model="form.matureFolliclesColour"
              >
                <option
                  v-for="option in colourOptions"
                  v-bind:value="option.value"
                  v-bind:key="option.value"
                >
                  {{ option.text }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="atretic-follicles laparoscopy-block-wrapper col-md-5">
          <h4 class="col-12 laparoscopy-block-title">Atretic follicles</h4>

          <div class="row">
          <div class="d-flex flex-column align-items-center col-6 my-2">
            <label class="text-end">Diameter</label>
            <input
              id="tagNumber"
              class="type-input col-md-10"
              v-model="form.atreticFolliclesDiameter"
              type="number"
            />
          </div>
          <div class="d-flex flex-column align-items-center col-6 my-2">
            <label class="text-end">Colour</label>
            <select
              class="type-input col-md-10"
              v-model="form.atreticFolliclesColour"
            >
              <option
                v-for="option in colourOptions"
                v-bind:value="option.value"
                v-bind:key="option.value"
              >
                {{ option.text }}
              </option>
            </select>
          </div>
        </div>
        </div>

        <div class="corpora-lutea laparoscopy-block-wrapper col-md-5">
          <h4 class="col-12 laparoscopy-block-title">Corpora lutea</h4>

          <div class="row">
            <div class="d-flex flex-column align-items-center col-4 my-2">
              <label class="text-end">Diameter</label>
              <input
                id="tagNumber"
                class="type-input col-10"
                v-model="form.corporaLuteaDiameter"
                type="number"
              />
            </div>
            <div class="d-flex flex-column align-items-center col-4 my-2">
              <label class="text-end">Appearance</label>
              <input
                id="tagNumber"
                class="type-input col-10"
                v-model="form.corporaLuteaAppearance"
              />
            </div>
            <div class="d-flex flex-column align-items-center col-4 my-2">
              <label class="text-end">Colour</label>
              <select class="type-input col-10" v-model="form.corporaLuteaColour">
                <option
                  v-for="option in colourOptions"
                  v-bind:value="option.value"
                  v-bind:key="option.value"
                >
                  {{ option.text }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="ovarian-scars laparoscopy-block-wrapper col-md-5">
          <h4 class="col-12 laparoscopy-block-title">Ovarian scars</h4>

          <div class="row">
            <div class="d-flex flex-column align-items-center col-6">
              <label class="text-end">Diameter</label>
              <input
                id="tagNumber"
                class="type-input col-md-10"
                v-model="form.ovarianScarsDiameter"
                type="number"
              />
            </div>
            <div class="d-flex flex-column align-items-center col-6">
              <label class="text-end">Colour</label>
              <select
                class="type-input col-md-10"
                v-model="form.ovarianScarsColour"
              >
                <option
                  v-for="option in colourOptions"
                  v-bind:value="option.value"
                  v-bind:key="option.value"
                >
                  {{ option.text }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="oviduct laparoscopy-block-wrapper col-md-5">
          <h4 class="col-12 laparoscopy-block-title">Oviduct</h4>

          <div class="row">
            <div class="d-flex flex-column align-items-center col-4 my-2">
              <label class="">Diameter opposite ovary</label>
              <input
                id="tagNumber"
                class="type-input col-10"
                v-model="form.oviductDiameter"
                type="number"
              />
            </div>
            <div class="d-flex flex-column align-items-center col-4 my-2">
              <label class="text-end">Shape</label>
              <select class="type-input col-10" v-model="form.oviductShape">
                <option
                  v-for="option in oviductShapeOptions"
                  v-bind:value="option.value"
                  v-bind:key="option.value"
                >
                  {{ option.text }}
                </option>
              </select>
            </div>
            <div class="d-flex flex-column align-items-center col-4 my-2">
              <label class="text-end">Colour</label>
              <select class="type-input col-10" v-model="form.oviductColour">
                <option
                  v-for="option in colourOptions"
                  v-bind:value="option.value"
                  v-bind:key="option.value"
                >
                  {{ option.text }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="ovary laparoscopy-block-wrapper col-md-5">
          <h4 class="col-12 laparoscopy-block-title">Ovary</h4>

          <div class="row">
            <div class="d-flex flex-column align-items-center col-12 my-2">
              <label class="text-end">Colour</label>
              <select class="type-input col-4" v-model="form.ovaryColour">
                <option
                  v-for="option in colourOptions"
                  v-bind:value="option.value"
                  v-bind:key="option.value"
                >
                  {{ option.text }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="other-details laparoscopy-block-wrapper col-md-5">
        <h4 class="col-12 laparoscopy-block-title">Other Details</h4>

        <div class="row">
          <div class="d-flex flex-column align-items-center col-6 my-2">
            <label class="">Any radiating white lines?</label>
            <input
              id="tagNumber"
              class="type-input col-10"
              v-model="form.radiatingWhiteLines"
              type="checkbox"
            />
          </div>
          <div class="d-flex flex-column align-items-center col-6 my-2">
            <label class="">Material Sampled</label>
            <input
              id="tagNumber"
              class="type-input col-10"
              v-model="form.femaleMaterialSampled"
            />
          </div>
        </div>
        <div class="row">
          <div class="d-flex flex-column align-items-center col-6 my-2">
            <label class="text-end">Photographs?</label>
            <input
              id="tagNumber"
              class="type-input col-10"
              v-model="form.femalePhotographs"
              type="checkbox"
            />
          </div>
          <div class="d-flex flex-column align-items-center col-6 my-2">
            <label class="text-end">Number of photos</label>
            <input
              id="tagNumber"
              class="type-input col-10"
              v-model="form.femaleNumberOfPhotos"
              type="number"
            />
          </div>
        </div>
      </div>
      </div>
    </div>
    <div class="encounter-form add-form my-4">
      <h4 class="detail-title p-2">Male</h4>

      <div class="row d-flex justify-content-evenly male-wrapper">
        <div class="testis laparoscopy-block-wrapper col-md-5">
          <h4 class="col-12 laparoscopy-block-title">Testis</h4>

          <div class="row">
            <div class="d-flex flex-column align-items-center col-6 my-2">
              <label class="text-end">Shape</label>
              <select class="type-input col-md-10" v-model="form.testisShape">
                <option
                  v-for="option in testisShapeOptions"
                  v-bind:value="option.value"
                  v-bind:key="option.value"
                >
                  {{ option.text }}
                </option>
              </select>
            </div>
            <div class="d-flex flex-column align-items-center col-6 my-2">
              <label class="text-end">Colour</label>
              <select class="type-input col-md-10" v-model="form.testisColour">
                <option
                  v-for="option in colourOptions"
                  v-bind:value="option.value"
                  v-bind:key="option.value"
                >
                  {{ option.text }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="epididymus laparoscopy-block-wrapper col-md-5">
          <h4 class="col-12 laparoscopy-block-title">Epididymus</h4>

          <div class="row">
            <div class="d-flex flex-column align-items-center col-4 my-2">
              <label class="text-end">Shape</label>
              <select class="type-input col-10" v-model="form.epididymusShape">
                <option
                  v-for="option in epididymusShapeOptions"
                  v-bind:value="option.value"
                  v-bind:key="option.value"
                >
                  {{ option.text }}
                </option>
              </select>
            </div>
            <div class="d-flex flex-column align-items-center col-4 my-2">
              <label class="text-end">Colour</label>
              <select class="type-input col-10" v-model="form.epididymusColour">
                <option
                  v-for="option in colourOptions"
                  v-bind:value="option.value"
                  v-bind:key="option.value"
                >
                  {{ option.text }}
                </option>
              </select>
            </div>
            <div class="d-flex flex-column align-items-center col-4 my-2">
              <label class="">White Tubules</label>
              <select
                class="type-input col-10"
                v-model="form.epididymusWhiteTubules"
              >
                <option
                  v-for="option in epididymusWhiteTubulesOptions"
                  v-bind:value="option.value"
                  v-bind:key="option.value"
                >
                  {{ option.text }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="paramesonephric-duct laparoscopy-block-wrapper col-md-5">
          <h4 class="col-12 laparoscopy-block-title">Paramesonephric duct</h4>

          <div class="row">
            <div class="d-flex flex-column align-items-center col-4 my-2">
              <label class="text-end">Presence</label>
              <select
                class="type-input col-10"
                v-model="form.paramesonephricPresence"
              >
                <option
                  v-for="option in paramesonephricPresenceOptions"
                  v-bind:value="option.value"
                  v-bind:key="option.value"
                >
                  {{ option.text }}
                </option>
              </select>
            </div>
            <div class="d-flex flex-column align-items-center col-4 my-2">
              <label class="text-end">Diameter</label>
              <input
                id="tagNumber"
                class="type-input col-10"
                v-model="form.paramesonephricDiameter"
                type="number"
              />
            </div>
            <div class="d-flex flex-column align-items-center col-4 my-2">
              <label class="text-end">Termination</label>
              <input
                id="tagNumber"
                class="type-input col-10"
                v-model="form.paramesonephricTermination"
              />
            </div>
          </div>
        </div>

        <div class="other-details laparoscopy-block-wrapper col-md-5">
          <h4 class="col-12 laparoscopy-block-title">Other Details</h4>

          <div class="row">
            <div class="d-flex flex-column align-items-center col-4 my-2">
              <label class="">Material Sampled</label>
              <input
                id="tagNumber"
                class="type-input col-10"
                v-model="form.maleMaterialSampled"
              />
            </div>
            <div class="d-flex flex-column align-items-center col-4 my-2">
              <label class="text-end">Photographs?</label>
              <input
                id="tagNumber"
                class="type-input col-10"
                v-model="form.malePhotographs"
                type="checkbox"
              />
            </div>
            <div class="d-flex flex-column align-items-center col-4 my-2">
              <label class="">Number of photos</label>
              <input
                id="tagNumber"
                class="type-input col-10"
                v-model="form.maleNumberOfPhotos"
                type="number"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="encounter-form add-form my-4">
      <h4 class="detail-title p-2">General</h4>

      <div class="row general-wrapper">
        <div class="col-6">
          <label class="col-sm-12 col-md-4">Number of Canulae</label>
          <input
            id="tagNumber"
            class="type-input col-10"
            v-model="form.canulaeCount"
            type="number"
          />
          <label class="col-12">Notes</label>
          <textarea
            id="notes"
            v-model="form.notes"
            placeholder="Notes"
            class="col-10 type-input"
          />
          <label class="col-sm-12 col-md-4 mt-2">Other organs examined</label>
          <input
            id="tagNumber"
            class="type-input col-10"
            v-model="form.otherOrgans"
            type="number"
          />
        </div>
        <div class="col col-6">
          <div class="d-flex justify-content-center">
            <label class="text-end col-4">Used Forceps?</label>
            <input
              id="tagNumber"
              class="type-input col-2"
              v-model="form.useForceps"
              type="checkbox"
            />
          </div>
          <div class="d-flex justify-content-center">
            <label class="text-end col-4">Used Biopsy Needle?</label>
            <input
              id="tagNumber"
              class="type-input col-2"
              v-model="form.useBiopsyNeedle"
              type="checkbox"
            />
          </div>
          <div class="d-flex justify-content-center">
            <label class="text-end col-4">Used Biopsy Forceps?</label>
            <input
              id="tagNumber"
              class="type-input col-2"
              v-model="form.useBiopsyForceps"
              type="checkbox"
            />
          </div>
          <div class="d-flex justify-content-center">
            <label class="text-end col-4">Used Biopsy Scissors?</label>
            <input
              id="tagNumber"
              class="type-input col-2"
              v-model="form.useBiopsyScissors"
              type="checkbox"
            />
          </div>
        </div>
      </div>
    </div>
    <div>
      <button
        href="#"
        class="save-button btn btn-primary mb-1"
        @click.prevent="saveLaparoscopy"
        type="submit"
      >
        Save Record
      </button>
      <br />
    </div>
  </div>
</template>

<script>
import BackButton from "@/components/BackButton.vue";

export default {
  name: "LaparoscopyForm",
  components: {
    BackButton,
  },
  mounted() {
    let encounterUUID = this.$route.params.uuid;
    if (this.$store.state.savedLaparoscopy[encounterUUID]) {
      this.form = JSON.parse(
        JSON.stringify(this.$store.state.savedLaparoscopy[encounterUUID])
      );
    } else {
      this.form.uuid = this.$route.params.uuid;
    }
  },
  data() {
    return {
      form: {
        uuid: null,
        researchMethod: "",
        researcher: "",
        stromaPrevitellogenicFollicles: "",
        developingFolliclesDiameter: "",
        developingFolliclesColour: "",
        matureFolliclesDiameter: "",
        matureFolliclesAbundance: "",
        matureFolliclesColour: "",
        atreticFolliclesDiameter: "",
        atreticFolliclesColour: "",
        corporaLuteaDiameter: "",
        corporaLuteaAppearance: "",
        corporaLuteaColour: "",
        ovarianScarsDiameter: "",
        ovarianScarsColour: "",
        oviductDiameter: "",
        oviductShape: "",
        oviductColour: "",
        ovaryColour: "",
        radiatingWhiteLines: "",
        femaleMaterialSampled: "",
        femalePhotographs: "",
        femaleNumberOfPhotos: "",
        testisShape: "",
        testisColour: "",
        epididymusShape: "",
        epididymusColour: "",
        epididymusWhiteTubules: "",
        paramesonephricPresence: "",
        paramesonephricDiameter: "",
        paramesonephricTermination: "",
        maleMaterialSampled: "",
        malePhotographs: "",
        maleNumberOfPhotos: "",
        canulaeCount: "",
        notes: "",
        otherOrgans: "",
        useForceps: "",
        useBiopsyNeedle: "",
        useBiopsyForceps: "",
        useBiopsyScissors: "",
      },
      researchMethodOptions: [
        { value: "D", text: "Dissection" },
        { value: "L", text: "Laparoscopy" },
      ],
      stromaPrevitellogenicFolliclesOptions: [
        { value: "", text: "N/A" },
        { value: "N", text: "Non-expanded" },
        { value: "E", text: "Expanded" },
        { value: "V", text: "Vesicular" },
      ],
      colourOptions: [
        { value: "", text: "N/A" },
        { value: "cream", text: "Cream" },
        { value: "pink", text: "Pink" },
        { value: "yellow", text: "Yellow" },
        { value: "yellow_orange", text: "Yellow-Orange" },
        { value: "white", text: "White" },
        { value: "other", text: "Other" },
      ],
      oviductShapeOptions: [
        { value: "", text: "N/A" },
        { value: "SC", text: "Slightly convoluted" },
        { value: "ST", text: "Straight" },
        { value: "VC", text: "Very convoluted" },
      ],
      testisShapeOptions: [
        { value: "", text: "N/A" },
        { value: "C", text: "Cylindrical" },
        { value: "E", text: "Ellipsoidal" },
        { value: "F", text: "Flat" },
      ],
      epididymusShapeOptions: [
        { value: "", text: "N/A" },
        { value: "DR", text: "Distinctly ridged" },
        { value: "NR", text: "Not obviously ridged" },
        { value: "PE", text: "Pendulous" },
      ],
      epididymusWhiteTubulesOptions: [
        { value: "", text: "N/A" },
        { value: "N", text: "No enlarged white tubule within" },
        { value: "Y", text: "White tubule obvious within" },
      ],
      paramesonephricPresenceOptions: [
        { value: "", text: "N/A" },
        { value: "N", text: "Absent" },
        { value: "U", text: "Not examined" },
        { value: "Y", text: "Present" },
      ],
    };
  },
  computed: {
    submitDisabled() {
      return !this.form.tagNumber;
    },
  },
  methods: {
    saveLaparoscopy() {
      this.$store.commit("saveLaparoscopy", this.form);
      this.$router.push({
        name: "EncounterMenu",
        params: { uuid: this.form.uuid },
      });
    },
  },
};
</script>
