<template>
  <div
    class="result-table"
    v-if="Object.keys(this.$store.state.savedEncounters).length > 0"
  >
    <h2>Saved Encounters</h2>
    <table class="table table-striped table-bordered">
      <thead class="bg-light">
        <th></th>
        <th>Encounter</th>
      </thead>
      <tbody>
        <tr
          v-for="encounter in this.$store.state.savedEncounters"
          :key="encounter.uuid"
        >
          <td>
            <div class="d-flex">
              <button
                @click.prevent="deleteRecord(encounter.uuid)"
                class="btn btn-sm btn-outline-danger m-1"
              >
                x
              </button>
              <router-link
                v-if="!encounter.uploaded"
                :to="{ name: 'EncounterMenu', params: { uuid: encounter.uuid } }"
              >
                <button class="btn btn-sm btn-outline-primary m-1">✎</button>
              </router-link>
            </div>
          </td>
          <td style="width: 100%">
            {{ this.$store.getters.getEncounterTitleByUUID(encounter.uuid) }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  </template>

<script>

export default {
  name: "EncounterTable",
  components: {
  },
  methods: {
    clearAll() {
      if (confirm("Are you sure you want to delete all saved encounters?")) {
        this.$store.commit("clearAllEncounters");
      }
    },
    clearUploaded() {
      if (
        confirm(
          "Are you sure you want to delete all encounters that have been uploaded?"
        )
      ) {
        this.$store.dispatch("clearUploadedEncounters");
      }
    },
    deleteRecord(uuid) {
      if (confirm("Are you sure you want to delete this encounter?")) {
        this.$store.commit("deleteEncounter", uuid);
        this.$store.commit("deleteSatelliteDetails", uuid);
        this.$store.commit("deleteMeasurementDetails", uuid);
        this.$store.commit("deleteGenetics", uuid);
        this.$store.commit("deleteLaparoscopy", uuid);
        this.$store.commit("deleteTumor", uuid);
      }
    },
    exportBackup() {
      let data = {};
      data.encounters = this.$store.state.savedEncounters;
      data.satellite = this.$store.state.savedSatelliteDetails;
      data.measurement = this.$store.state.savedMeasurements;
      data.genetics = this.$store.state.savedGenetics;
      data.laparoscopy = this.$store.state.savedLaparoscopy;
      data.tumor = this.$store.state.savedTumor;

      var element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," +
          encodeURIComponent(JSON.stringify(data))
      );
      element.setAttribute("download", "treds_encounters.json");

      element.style.display = "none";
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    },
    importFromFile() {
      const target = document.getElementById("importInput");
      const file = target.files[0];
      if (!file) {
        return;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        const contents = e.target.result;
        let importedData = JSON.parse(contents);
        this.$store.commit("setAllEncounters", importedData.encounters);
        this.$store.commit("setAllGenetics", importedData.genetics);
        this.$store.commit("setAllLaparoscopy", importedData.laparoscopy);
        this.$store.commit("setAllMeasurements", importedData.measurement);
        this.$store.commit("setAllSatelliteDetails", importedData.satellite);
        this.$store.commit("setAllTumor", importedData.tumor);
      };
      target.value = "";
      reader.readAsText(file);
    },
  },
};
</script>

<style>
</style>
