import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'vue-multiselect/dist/vue-multiselect.css'
import '@/assets/css/main.css'

createApp(App).use(store).use(router).mount('#app')

store.dispatch('initialSetup')

window.addEventListener('offline', function (e) { store.commit('offline'); });

window.addEventListener('online', function (e) { store.commit('online'); });

if(navigator.onLine) {
    store.commit('online')
} else { 
    store.commit('offline')
}